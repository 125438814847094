import { useMapEvent } from "vector-maps/dist/hooks/useMapEvent";
import Popup from "vector-maps/dist/features/Popup";
import { useMap } from "vector-maps/dist/MapContext";
import React from "react";
import { useState } from "react";
import { TracesLayer } from "../../TracesLayer";
import { Feature, FeatureCollection, featureCollection } from "@turf/turf";
import { LngLat } from "mapbox-gl";
import NukusColoredTrace from "../FlowMatrix/FlowMatrixDebugColoredTrace";
import NukusTracesList from "../NukusTracesList";
import { FlowMatrixDebugTraceProps } from "../../../api/model/flowMatrixDebug";

export function FlowMatrixDebugTracesSelector({
  nukusTraces,
  setSelectedTraceId,
}: {
  nukusTraces: FeatureCollection<any, FlowMatrixDebugTraceProps>;
  setSelectedTraceId: (id?: string) => void;
}) {
  const { map } = useMap();
  const [queriedTraces, setQueriedTraces] = useState<any>();
  const [hoveredTrace, setHoveredTrace] = useState<any>();
  const [clickPosition, setClickPosition] = useState<LngLat | undefined>();

  useMapEvent("click", (e) => {
    const mapZoom = map.getZoom();
    const bbox = [
      [e.point.x - mapZoom, e.point.y - mapZoom],
      [e.point.x + mapZoom, e.point.y + mapZoom],
    ];
    const features = map.queryRenderedFeatures(bbox as any, {
      layers: ["traces-lines1"],
    });
    const ids = features.map((it) => it.properties?.id);

    if (ids.length === 0) {
      setQueriedTraces(undefined);
      setHoveredTrace(undefined);
      return;
    }

    const traces: Feature[] = [];

    nukusTraces.features.forEach((it: any) => {
      if (ids.includes(it.properties?.id)) {
        traces.push(it);
      }
    });

    const originalFeatures = traces;

    console.log({ clickedTraces: originalFeatures });

    if (originalFeatures.length === 1) {
      setSelectedTraceId(originalFeatures[0]?.properties?.id);
      setQueriedTraces(undefined);
      setHoveredTrace(undefined);
      return;
    }

    setClickPosition(map.unproject(e.point));
    setQueriedTraces(featureCollection(originalFeatures.slice(0, 20)));
    setHoveredTrace(undefined);
  });

  useMapEvent("contextmenu", () => {
    setSelectedTraceId(undefined);
  });

  return (
    <>
      <TracesLayer color="white" traces={queriedTraces} showTraces showPoints />
      {queriedTraces?.features.length > 0 && clickPosition !== undefined && (
        <Popup
          offset={[0, 5]}
          anchor="top"
          tipSize={5}
          lat={clickPosition.lat}
          lng={clickPosition.lng}
          content={
            <NukusTracesList
              queriedTraces={queriedTraces?.features ?? []}
              setChosenTrace={(it) => {
                setQueriedTraces(undefined);
                setHoveredTrace(undefined);
                setSelectedTraceId(it?.properties?.id);
              }}
              setHoveredTrace={setHoveredTrace}
            />
          }
          onPopupClose={() => {
            setQueriedTraces(undefined);
          }}
          onClickOutside={() => {
            setQueriedTraces(undefined);
          }}
        />
      )}
      {hoveredTrace && (
        <NukusColoredTrace
          sourceId="hoveredTrace"
          trace={hoveredTrace}
          hovered={true}
          color="#2f00ff"
        />
      )}
    </>
  );
}
