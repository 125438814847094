import * as React from "react";
import { Box, Button, Modal } from "tombac";
import { EditIcon } from "tombac-icons";
import { Editor } from "./Editor";

export function RegionSection({
  geometry,
  setGeometry,
}: {
  geometry: any;
  setGeometry: (g: any) => void;
}) {
  const [editorOpen, setEditorOpen] = React.useState(false);
  const [pendingGeometry, setPendingGeometry] = React.useState(geometry);

  return (
    <>
      <Modal isOpen={editorOpen}>
        <Editor
          height="50vh"
          width="50vw"
          value={pendingGeometry}
          onChange={setPendingGeometry}
        />
        <Box p={2} display="flex" style={{ background: "#333" }}>
          <Button
            ml="auto"
            onClick={() => {
              setEditorOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            ml={2}
            variant="primary"
            onClick={() => {
              setGeometry(pendingGeometry);
              setEditorOpen(false);
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>

      <Button
        prepend={<EditIcon width="12px" />}
        onClick={() => {
          setPendingGeometry(geometry);
          setEditorOpen(true);
        }}
        style={{ flexShrink: 0 }}
        mr={1}
      >
        Region
      </Button>
    </>
  );
}
