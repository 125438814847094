import { Filter } from "../commonTypes";

export const getCorrectAndActiveFilters = (filters: Filter[]) => {
  let correctFilters: Filter[] = [];
  try {
    correctFilters = [...filters]
      .filter(
        (filter) =>
          filter.active &&
          (!filter.params.length || filter.params.filter((f) => f.value).length)
      )
      .map((filter: any) => {
        const { active, ...copy } = filter;
        return copy;
      });
  } catch (e) {
    console.log(e);
  }
  return correctFilters;
};
