import { useCallback, useEffect, useState } from "react";
import { api } from "../api";
import { Filter } from "../commonTypes";
import { BASE_API_URL } from "../constants/api";

interface Response {
  settings: Filter[];
}

export const useFilters = () => {
  const [filters, setFilters] = useState<Response>();
  const [isLoading, setIsLoading] = useState(true);

  const load = useCallback(async () => {
    setIsLoading(true);
    try {
      const collection = await api(
        `${BASE_API_URL}/processors?code=BT66SKPmf1nVjsmDXxZDf3EJYisjRiMelAIm/sf5sqWC5liyt9e7XA==`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setFilters(collection);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return {
    collection: filters?.settings,
    isLoading,
  };
};
