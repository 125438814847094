import React, { useState } from "react";
import { ChevronSDownIcon, ChevronSUpIcon } from "tombac-icons";
import { Box, Heading } from "tombac";

export function Section({
  defaultOpen = false,
  title,
  children,
  actions,
}: {
  defaultOpen?: boolean;
  title: any;
  children: any;
  actions?: any;
}) {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <Box style={{ borderTop: "1px solid #eee" }}>
      <div
        role={"button"}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          padding: "8px 15px",
        }}
        onClick={() => setOpen((it) => !it)}
      >
        {open ? (
          <ChevronSUpIcon width="14px" />
        ) : (
          <ChevronSDownIcon width="14px" />
        )}

        <Heading level={4} ml={1} display="flex" alignItems="center">
          {title}
        </Heading>

        {actions}
      </div>
      {open && (
        <Box display="flex" flexDirection="column" mt={1} mb={2}>
          {children}
        </Box>
      )}
    </Box>
  );
}
