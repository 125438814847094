import React, { useMemo } from "react";
import { Box, Button } from "tombac";
import * as traceUtils from "../../../utils/traces";
import "rc-slider/assets/index.css";
import { Feature, Geometry } from "@turf/turf";
import { FlowMatrixDebugTraceProps } from "../../../api/model/flowMatrixDebug";

const FlowMatrixDebugTraceInfo = ({
  trace,
}: {
  trace: Feature<Geometry, FlowMatrixDebugTraceProps>;
}) => {
  const traceStats = useMemo(() => {
    return {
      length: trace.properties?.lengthInMeters!! / 1000,
      avgSpeed: traceUtils.toKmh(trace.properties?.avgSpeedMs!!),
      org: trace.properties?.org,
    };
  }, [trace]);

  if (!trace) return null;

  return (
    <Box
      style={{
        background: "#FFF",
        position: "fixed",
        bottom: 0,
        right: 0,
        margin: "10px",
        borderRadius: "10px",
      }}
      p={3}
      margin={2}
      zIndex={1}
    >
      <b>Trace id:</b> {trace.properties?.id}
      <div>
        <div>
          <b>Length:</b>
          {` ${traceUtils.length(trace).toFixed(2)} km`}
        </div>
        <div>
          <b>Average speed:</b>
          {` ${traceStats.avgSpeed.toFixed(2)} km/h`}
        </div>
        <div>
          <b>Organization:</b>
          {` ${traceStats.org}`}
        </div>
        {trace.properties?.failCause && (
          <div>
            <b>Mm error:</b>
            {` ${[...new Set(trace.properties?.failCause)]
              .filter((it) => it !== undefined)
              .join(", ")}`}
          </div>
        )}
      </div>
      <Button
        mt={1}
        $width={"100%"}
        onClick={(e) => traceUtils.saveTraceToFile(trace)}
      >
        Save trace as geoJSON
      </Button>
    </Box>
  );
};

export default FlowMatrixDebugTraceInfo;
