import React, { useState } from "react";
import { useFlowMatrixDebugTraces } from "../../../hooks/useFlowMatrixDebugTraces";
import { SpinnerIcon } from "tombac-icons";
import { Alert } from "tombac";
import { ControlsContainer } from "../../TracesPage";
import { useRegions } from "../../../hooks/useRegions";
import { LoadingContainer } from "../../common.style";
import { FlowMatrixDebug } from "./FlowMatrixDebug";
import { TracesOrigin } from "../../../api/model/flowMatrixDebug";

export const FlowMatrixDebugPage = () => {
  const [tracesOrigin, setTracesOrigin] = useState<TracesOrigin>(
    TracesOrigin.ANALYSIS
  );
  const {
    rawTraces,
    mapMatchedTraces,
    rawTracesTrimmedForMapMatching,
    isLoading: isTracesLoading,
    isError: isTracesError,
  } = useFlowMatrixDebugTraces(tracesOrigin === TracesOrigin.FAILED);
  const {
    regions,
    isLoading: isLoadingRegions,
    isError: isErrorRegions,
  } = useRegions();

  if (isTracesLoading || isLoadingRegions) {
    return (
      <LoadingContainer>
        <SpinnerIcon size={7} color="#ffffff" spin={true} />
      </LoadingContainer>
    );
  }

  if (
    isTracesError ||
    !rawTraces ||
    !mapMatchedTraces ||
    !rawTracesTrimmedForMapMatching ||
    isErrorRegions ||
    !regions
  ) {
    return (
      <LoadingContainer>
        <Alert $width="100%" title="Danger!" variant="danger">
          Unable to fetch traces
        </Alert>
      </LoadingContainer>
    );
  }

  return (
    <ControlsContainer>
      <FlowMatrixDebug
        rawTraces={rawTraces}
        mapMatchedTraces={mapMatchedTraces}
        rawTracesTrimmedForMapMatching={rawTracesTrimmedForMapMatching}
        regions={regions}
        tracesOrigin={tracesOrigin}
        setTracesOrigin={setTracesOrigin}
      />
    </ControlsContainer>
  );
};
