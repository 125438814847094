import React from "react";
import styled from "styled-components";
import { Box, Button, Checkbox } from "tombac";

const WhiteBox = styled(Box)`
  background: white;
`;

const OrgsSelectorBox = styled(WhiteBox)`
  display: flex;
  flex-direction: column;
  margin: 10px;
  max-height: 70vh;
  overflow: auto;
`;

interface Props {
  featureByOrg: Map<string, number>;
  selectedOrgs: Set<string>;
  setSelectedOrgs: (organizations: Set<string>) => void;
}

export const OrgsSelector = ({
  featureByOrg,
  selectedOrgs,
  setSelectedOrgs,
}: Props) => {
  const toggleOrg = (org: string) => {
    const newOrganizations = new Set(selectedOrgs);
    if (newOrganizations.has(org)) {
      newOrganizations.delete(org);
    } else {
      newOrganizations.add(org);
    }
    setSelectedOrgs(newOrganizations);
  };

  const deselectAll = () => {
    setSelectedOrgs(new Set());
  };

  const selectAll = () => {
    setSelectedOrgs(new Set(featureByOrg.keys()));
  };

  return (
    <>
      <OrgsSelectorBox>
        {Array.from(featureByOrg).map(([org, tracesNumber]) => (
          <Checkbox
            key={org}
            label={`${org} (${tracesNumber})`}
            checked={selectedOrgs.has(org)}
            style={{ padding: "3px" }}
            onChange={() => toggleOrg(org)}
          />
        ))}
        <Button onClick={deselectAll}>Deselect all</Button>
        <Button style={{ marginTop: "10px" }} onClick={selectAll}>
          Select all
        </Button>
      </OrgsSelectorBox>
    </>
  );
};
