import * as React from "react";
import { ControlledEditor } from "@monaco-editor/react";

export function Editor<T>({
  value,
  onChange,
  height = "50vh",
  width = "100%",
}: {
  value: T;
  onChange: (r: T) => void;
  height?: string;
  width?: string;
}) {
  const [plaintext, setPlaintext] = React.useState(
    JSON.stringify(value, null, 2)
  );

  React.useEffect(() => {
    try {
      const parsed = JSON.parse(plaintext);
      onChange(parsed);
    } catch (e) {}
  }, [plaintext, onChange]);

  return (
    <ControlledEditor
      height={height}
      width={width}
      language="json"
      theme="dark"
      value={plaintext}
      options={{ minimap: { enabled: false } }}
      onChange={(_, value) => setPlaintext(value ?? "")}
    />
  );
}
