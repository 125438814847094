import { parse } from "csv-parse/lib";
import { FlowMatrixDebugTrace } from "../api/model/flowMatrixDebug";

const headers = [
  "id",
  "avgSpeedMs",
  "lengthInMeters",
  "org",
  "rawGeometry",
  "rawTrimmedForMapMatchingGeometry",
  "mapMatchedGeometry",
  "failCause",
];

export const readCsvData = async (
  values: string
): Promise<FlowMatrixDebugTrace[]> => {
  const results = await new Promise<FlowMatrixDebugTrace[]>(
    (resolve, reject) => {
      parse(
        values,
        {
          delimiter: ",",
          from_line: 2,
          columns: headers,
        },

        (error, result: FlowMatrixDebugTrace[]) => {
          if (error || !result) {
            reject(error);
          } else {
            resolve(result);
          }
        }
      );
    }
  );

  return results;
};
