export const generateLinePattern = (primary: number[], secondary: number[]) => {
  const primaryRGB = primary;
  const secondaryRGB = secondary;

  const bytesPerPixel = 4;
  const arrowPattern = [
    [0, 0, 0, 0, 0, 0, 0],
    [1, 0, 0, 0, 0, 0, 1],
    [1, 1, 0, 0, 0, 1, 1],
    [1, 1, 1, 0, 1, 1, 1],
    [0, 1, 1, 1, 1, 1, 0],
    [0, 0, 1, 1, 1, 0, 0],
    [0, 0, 0, 1, 0, 0, 0],
  ];
  const width = arrowPattern.length;
  const data = new Uint8Array(width * width * bytesPerPixel);
  const alphaOffset = 3;
  const stripeAlpha = 255;
  const backgroundAlpha = 255;

  for (let x = 0; x < width; x += 1) {
    for (let y = 0; y < width; y += 1) {
      const offset = (y * width + x) * bytesPerPixel;
      if (arrowPattern[x][y]) {
        data[offset] = primaryRGB[0];
        data[offset + 1] = primaryRGB[1];
        data[offset + 2] = primaryRGB[2];
        data[offset + alphaOffset] = stripeAlpha;
      } else {
        data[offset] = secondaryRGB[0];
        data[offset + 1] = secondaryRGB[1];
        data[offset + 2] = secondaryRGB[2];
        data[offset + alphaOffset] = backgroundAlpha;
      }
    }
  }

  return { width, height: width, data };
};
