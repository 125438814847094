import { FeatureCollection, Geometry } from "@turf/turf";
import React from "react";
import { Box, Label, Modal, RadioGroup } from "tombac";
import {
  FlowMatrixDebugTraceProps,
  TracesOrigin,
} from "../../../../api/model/flowMatrixDebug";
import { UploadCsv } from "./UploadCsv";

const tracesOriginOptions = [
  { label: "Analysis", value: TracesOrigin.ANALYSIS },
  { label: "Failed", value: TracesOrigin.FAILED },
];

interface Props {
  traceOrigin: TracesOrigin;
  setTraceOrigin: (traceOrigin: TracesOrigin) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setRawTraces: (
    traces: FeatureCollection<Geometry, FlowMatrixDebugTraceProps>
  ) => void;
  setRawTracesTrimmedForMapMatching: (
    traces: FeatureCollection<Geometry, FlowMatrixDebugTraceProps>
  ) => void;
  setMapMatchedTraces: (
    traces: FeatureCollection<any, FlowMatrixDebugTraceProps>
  ) => void;
}

export const CsvUploadModal = ({
  traceOrigin,
  setTraceOrigin,
  setRawTraces,
  setMapMatchedTraces,
  setRawTracesTrimmedForMapMatching,
  isOpen,
  setIsOpen,
}: Props) => {
  return (
    <Modal isOpen={isOpen}>
      <Box
        $padding="20px"
        $display="flex"
        $flexDirection="column"
        $justifyContent="space-between"
        $alignItems="center"
        $gap="30px"
        style={{ background: "#e7e4e4" }}
      >
        <Box>
          <Label
            style={{
              fontSize: 15,
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            Traces origin
          </Label>
          <RadioGroup
            $borderRadius="10px"
            onChange={(option) => setTraceOrigin(option?.value!!)}
            options={tracesOriginOptions}
            value={tracesOriginOptions.find((it) => it.value === traceOrigin)}
            variant="horizontal"
          />
        </Box>

        <UploadCsv
          setIsOpen={setIsOpen}
          setMapMatchedTraces={setMapMatchedTraces}
          setRawTraces={setRawTraces}
          setRawTracesTrimmedForMapMatching={setRawTracesTrimmedForMapMatching}
        />
      </Box>
    </Modal>
  );
};
