import React from "react";
import { Checkbox } from "tombac";
import { Filter } from "../commonTypes";
import { pascalCaseToHumanFriendly } from "../utils/string";
import GeometricFilterParam from "./GeometricFilterParam";
import NumericFilterParam from "./NumericFilterParam";
import { Section } from "./Section";

interface Props {
  originalFilter: Filter;
  setFilter: (filters: Filter) => void;
}

const FilterOptions: React.FC<Props> = ({ originalFilter, setFilter }) => {
  const editFilter = (value: string | number | null, index: number) => {
    const newFilter = { ...originalFilter } as Filter;
    newFilter.params[index].value = value;
    setFilter(newFilter);
  };

  const toggleFilter = () => {
    const newFilter = { ...originalFilter } as Filter;
    newFilter.active = !newFilter.active;
    setFilter(newFilter);
  };

  return (
    <>
      <Section
        title={
          pascalCaseToHumanFriendly(originalFilter.name) +
          (originalFilter.active ? " -- Active" : "")
        }
      >
        <div
          style={{ paddingRight: "20px", paddingLeft: "20px", marginBottom: 0 }}
        >
          <Checkbox
            checked={originalFilter.active}
            label={"Active"}
            onChange={toggleFilter}
          />
          {originalFilter.params.map((param, i) => (
            <div key={param.name}>
              <>
                {param.type === "GEOMETRY" ? (
                  <GeometricFilterParam
                    filterActive={originalFilter.active}
                    editParam={editFilter}
                    index={i}
                    name={param.name}
                    geometry={param.value}
                  />
                ) : (
                  <NumericFilterParam
                    filterActive={originalFilter.active}
                    editParam={editFilter}
                    index={i}
                    name={param.name}
                    value={param.value}
                  />
                )}
              </>
            </div>
          ))}
        </div>
      </Section>
    </>
  );
};

export default FilterOptions;
