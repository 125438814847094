import { Button, HFlex, Label } from "tombac";
import React from "react";
import { SettingsIcon } from "tombac-icons";

interface Props {
  setEditorOpen: (modalOpen: boolean) => void;
  tracesNumber: number;
}

export const TracesConfig = ({ setEditorOpen, tracesNumber }: Props) => {
  return (
    <>
      <HFlex justifyContent="space-between">
        <Label
          style={{
            fontSize: 15,
            margin: "2px 2px",
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          Selected trips: {tracesNumber}
        </Label>
        <Button
          size="xs"
          $width="32px"
          $height="30px"
          $borderRadius="100%"
          onClick={() => setEditorOpen(true)}
        >
          <SettingsIcon />
        </Button>
      </HFlex>
    </>
  );
};
