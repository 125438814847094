import React from "react";
import styled from "styled-components";
import { Error404Vulture } from "tombac";

export const NotFoundContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
  min-height: 100vh;
`;

export const NotFoundPage = () => {
  return (
    <NotFoundContainer>
      <Error404Vulture width={480} />
    </NotFoundContainer>
  );
};
