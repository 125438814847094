import React, { useState } from "react";
import { TracesFetchSettings } from "./TracesFetchSettings";
import { Feature } from "@turf/turf";
import { SelectedLinkDebugResults } from "./SelectedLinkDebugResults";

export const SelectedLinkDebugPage = () => {
  const [editorOpen, setEditorOpen] = useState(true);
  const [geometry, setGeometry] = useState<Feature[]>([]);
  const [limit, setLimit] = useState<number>(500);
  const [filterByGeometry, setFilterByGeometry] = useState<boolean>(false);
  const [selectedLinkResultType, setSelectedLinkResultType] = useState<
    string | undefined
  >("INCOMING");

  return (
    <>
      <TracesFetchSettings
        editorOpen={editorOpen}
        setEditorOpen={setEditorOpen}
        geometry={geometry}
        setGeometry={setGeometry}
        selectedLinkResultType={selectedLinkResultType}
        setSelectedLinkResultType={setSelectedLinkResultType}
        limit={limit}
        setLimit={setLimit}
        filterByGeometry={filterByGeometry}
        setFilterByGeometry={setFilterByGeometry}
      />
      {(geometry?.length > 0 || !filterByGeometry) && !editorOpen && (
        <SelectedLinkDebugResults
          resultType={selectedLinkResultType!!}
          limit={limit}
          setEditorOpen={setEditorOpen}
          geometry={geometry}
          filterByGeometry={filterByGeometry}
        />
      )}
    </>
  );
};
