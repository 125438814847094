// const hostname = window.location.hostname;

// const port = 7071;

// export const BASE_API_URL = `http://${hostname}:${port}/api`;
export const BASE_API_URL = "https://delemont.ts.tomtom.com/api";

export const NUKUS_BASE_API_URL = "https://api.tomtom.com/origindestination/1";

export const DELEMONT_FUNCTION_CODE =
  "BT66SKPmf1nVjsmDXxZDf3EJYisjRiMelAIm/sf5sqWC5liyt9e7XA==";
