import { FeatureCollection, Polygon } from "@turf/turf";
import { GeometryLayer } from "../GeometryLayer";
import React from "react";

interface Props {
  regions: FeatureCollection<Polygon, { name: string }>;
}

export const RegionsLayer = ({ regions }: Props) => {
  return (
    <>
      {regions.features.map((region) => (
        <GeometryLayer geometry={region} />
      ))}
    </>
  );
};
