import { useEffect } from "react";
import { useMap } from "vector-maps/dist/MapContext";
import { Layer, Map } from "mapbox-gl";
import { featureCollection, FeatureCollection, point } from "@turf/turf";
import { useLayers } from "../../../hooks/useLayers";
import arrowTriangleAlpha from "../arrow-triangle-alpha.svg";
import { getFirstAndLastPoint } from "../../../utils/geometry";
import {
  traceEndPointLayer,
  traceStartPointLayer,
} from "../../../utils/layers";

export const RAW_TRACE_COLOR = "#ff0000";
export const MAP_MATCHED_TRACE_COLOR = "#0000ff";
export const FINAL_TRACE_COLOR = "#3da63d";

const layersNames = {
  rawLines: "raw-traces-lines",
  rawPoints: "raw-traces-points",
  rawTraceSymbol: "raw-traces-symbol",
  finalLines: "trimmed-traces-lines",
  finalPoints: "trimmed-traces-points",
  finalTraceSymbol: "trimmed-traces-symbol",
  mapMatchedLines: "map-matched-traces-lines",
  mapMatchedPoints: "map-matched-traces-points",
  mapMatchedTraceSymbol: "map-matched-traces-symbol",
};

const layers = [
  {
    id: layersNames.rawLines,
    type: "line",
    filter: ["==", ["get", "type"], "RAW"],
    paint: {
      "line-width": 5,
      "line-color": RAW_TRACE_COLOR,
    },
    layout: {
      "line-cap": "butt",
      "line-join": "bevel",
    },
  },
  {
    id: layersNames.rawPoints,
    type: "circle",
    filter: ["==", ["get", "type"], "RAW"],
    paint: {
      "circle-radius": {
        base: 4,
        stops: [
          [12, 4.5],
          [14, 5],
          [16, 7],
        ],
      },
      "circle-color": RAW_TRACE_COLOR,
      "circle-stroke-width": 0.5,
      "circle-stroke-color": "#000000",
    },
  },
  {
    id: layersNames.rawTraceSymbol,
    type: "symbol",
    filter: ["==", ["get", "type"], "RAW"],
    layout: {
      "icon-image": "arrows",
      "icon-size": 0.75,
      "symbol-placement": "line",
      "symbol-spacing": 30,
    },
  },
  {
    id: layersNames.mapMatchedLines,
    type: "line",
    filter: ["==", ["get", "type"], "MAP_MATCHED"],
    paint: {
      "line-width": 5,
      "line-color": MAP_MATCHED_TRACE_COLOR,
    },
    layout: {
      "line-cap": "butt",
      "line-join": "bevel",
    },
  },
  {
    id: layersNames.mapMatchedPoints,
    type: "circle",
    filter: ["==", ["get", "type"], "MAP_MATCHED"],
    paint: {
      "circle-radius": {
        base: 1,
        stops: [
          [12, 4.5],
          [14, 5],
          [16, 7],
        ],
      },
      "circle-color": MAP_MATCHED_TRACE_COLOR,
      "circle-stroke-width": 0.5,
      "circle-stroke-color": "#000000",
    },
  },
  {
    id: layersNames.mapMatchedTraceSymbol,
    type: "symbol",
    filter: ["==", ["get", "type"], "MAP_MATCHED"],
    layout: {
      "icon-image": "arrows",
      "icon-size": 0.75,
      "symbol-placement": "line",
      "symbol-spacing": 30,
    },
  },
  {
    id: layersNames.finalLines,
    type: "line",
    filter: ["==", ["get", "type"], "FINAL"],
    paint: {
      "line-width": 5,
      "line-color": FINAL_TRACE_COLOR,
    },
    layout: {
      "line-cap": "butt",
      "line-join": "bevel",
    },
  },
  {
    id: layersNames.finalPoints,
    type: "circle",
    filter: ["==", ["get", "type"], "FINAL"],
    paint: {
      "circle-radius": {
        base: 4,
        stops: [
          [12, 4.5],
          [14, 5],
          [16, 7],
        ],
      },
      "circle-color": FINAL_TRACE_COLOR,
      "circle-stroke-width": 0.5,
      "circle-stroke-color": "#000000",
    },
  },
  {
    id: layersNames.finalTraceSymbol,
    type: "symbol",
    filter: ["==", ["get", "type"], "FINAL"],
    layout: {
      "icon-image": "arrows",
      "icon-size": 0.75,
      "symbol-placement": "line",
      "symbol-spacing": 30,
    },
  },
] as Layer[];

const linkLayers = [
  {
    id: "outline",
    type: "line",
    paint: {
      "line-width": 14,
      "line-color": "rgb(192,226,253)",
    },
    layout: {
      "line-cap": "round",
    },
  },
  {
    id: "line",
    type: "line",
    paint: {
      "line-width": 9,
      "line-color": "rgba(42,145,213,1)",
    },
    layout: {
      "line-cap": "round",
    },
  },
] as Layer[];

interface Props {
  traces: FeatureCollection;
  link: FeatureCollection;
}

export const TracesLayer = ({ traces, link }: Props) => {
  const map = useMap().map as Map;

  const rawTrace = traces.features.find((it) => it.properties?.type === "RAW");
  const mapMatchedTrace = traces.features.find(
    (it) => it.properties?.type === "MAP_MATCHED"
  );
  const finalTrace = traces.features.find(
    (it) => it.properties?.type === "FINAL"
  );

  console.log("rawTrace", rawTrace);

  const rawFirstAndLastCoordinate = getFirstAndLastPoint(rawTrace);
  const mapMatchedFirstLastCoordinate = getFirstAndLastPoint(mapMatchedTrace);
  const finalFirstLastCoordinate = getFirstAndLastPoint(finalTrace);

  useLayers("traces", layers, traces, "outline-link");

  useLayers(
    `sourceId-raw-start-point`,
    traceStartPointLayer,
    featureCollection(
      rawFirstAndLastCoordinate?.at(0)
        ? [point(rawFirstAndLastCoordinate?.at(0))]
        : []
    )
  );

  useLayers(
    `sourceId-raw-end-point`,
    traceEndPointLayer,
    featureCollection(
      rawFirstAndLastCoordinate?.at(1)
        ? [point(rawFirstAndLastCoordinate?.at(1))]
        : []
    )
  );

  useLayers(
    `sourceId-map-matched-start-point`,
    traceStartPointLayer,
    featureCollection(
      mapMatchedFirstLastCoordinate?.at(0)
        ? [point(mapMatchedFirstLastCoordinate?.at(0))]
        : []
    )
  );

  useLayers(
    `sourceId-map-matched-end-point`,
    traceEndPointLayer,
    featureCollection(
      mapMatchedFirstLastCoordinate?.at(1)
        ? [point(mapMatchedFirstLastCoordinate?.at(1))]
        : []
    )
  );

  useLayers(
    `sourceId-final-start-point`,
    traceStartPointLayer,
    featureCollection(
      finalFirstLastCoordinate?.at(0)
        ? [point(finalFirstLastCoordinate?.at(0))]
        : []
    ),
    "outline-link"
  );

  useLayers(
    `sourceId-final-end-point`,
    traceEndPointLayer,
    featureCollection(
      finalFirstLastCoordinate?.at(1)
        ? [point(finalFirstLastCoordinate?.at(1))]
        : []
    ),
    "outline-link"
  );

  useLayers("-link", linkLayers, link);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      if (map.hasImage("arrows")) return;
      map.addImage("arrows", img);
    };
    img.src = arrowTriangleAlpha;

    return () => {
      map.removeImage("arrows");
    };
  }, [map]);

  return null;
};
