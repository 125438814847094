import React from "react";
import styled from "styled-components";
import { Box, Checkbox, Label, RadioGroup } from "tombac";
import { OrgsSelector } from "../OrgsSelector";
import { Section } from "../../Section";
import {
  FlowMatrixDebugTraceProps,
  FlowMatrixDebugTraceType,
  TracesOrigin,
  ViewMode,
} from "../../../api/model/flowMatrixDebug";
import { TraceViewerControls } from "./TraceViewerControls";
import { FeatureCollection } from "@turf/turf";
import { MapMatchingErrorsCauseSelector } from "./MapMatchingErrorsCauseSelector";

const WhiteBox = styled(Box)`
  background: white;
`;

const ControlsBox = styled(WhiteBox)`
  display: flex;
  flex-direction: column;
  margin: 10px;
  margin-top: 0;
  width: 70%;
  border-radius: 10px;
`;

const traceTypeOptions = [
  { label: "Raw", value: FlowMatrixDebugTraceType.RAW },
  { label: "Map matched", value: FlowMatrixDebugTraceType.MAP_MATCHED },
];

const viewModeOptions = [
  { label: "All", value: ViewMode.ALL },
  { label: "Single", value: ViewMode.SINGLE },
];

const tracesOriginOptions = [
  { label: "Analysis", value: TracesOrigin.ANALYSIS },
  { label: "Failed", value: TracesOrigin.FAILED },
];

interface Props {
  filteredTracesSize: number;
  filteredTraces: FeatureCollection<any, FlowMatrixDebugTraceProps>;
  traces: FeatureCollection<any, FlowMatrixDebugTraceProps>;
  selectedTraceId?: string;
  setSelectedTraceId: (id: string) => void;
  featureByOrg: Map<string, number>;
  selectedOrgs: Set<string>;
  setSelectedOrgs: (orgs: Set<string>) => void;
  showRegions: boolean;
  setShowRegions: (showRegions: boolean) => void;
  selectedTracesType: FlowMatrixDebugTraceType;
  setSelectedTracesType: (traceType: FlowMatrixDebugTraceType) => void;
  viewMode: ViewMode;
  setViewMode: (viewMode: ViewMode) => void;
  isMapMatched: boolean;
  tracesOrigin: TracesOrigin;
  setTracesOrigin: (failedTraces: TracesOrigin) => void;
  selectedMapMatchingErrorsCauses?: Set<string>;
  setSelectedMapMatchingErrorsCauses: (causes: Set<string>) => void;
}

export const FlowMatrixDebugControls = ({
  filteredTracesSize,
  filteredTraces,
  selectedTraceId,
  setSelectedTraceId,
  featureByOrg,
  selectedOrgs,
  setSelectedOrgs,
  showRegions,
  setShowRegions,
  selectedTracesType,
  setSelectedTracesType,
  viewMode,
  setViewMode,
  isMapMatched,
  tracesOrigin,
  setTracesOrigin,
  traces,
  selectedMapMatchingErrorsCauses,
  setSelectedMapMatchingErrorsCauses,
}: Props) => {
  return (
    <ControlsBox>
      {isMapMatched && (
        <RadioGroup
          $borderRadius="10px"
          value={tracesOriginOptions.find((it) => it.value === tracesOrigin)}
          onChange={(e) => setTracesOrigin(e?.value!!)}
          options={tracesOriginOptions}
          variant={"horizontal"}
        />
      )}
      <Label
        style={{
          fontSize: 15,
          margin: "4px 4px",
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        Selected trips: {filteredTracesSize}
      </Label>

      {isMapMatched && (
        <>
          <Label
            style={{
              fontSize: 15,
              marginTop: "2px",
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            ViewMode
          </Label>
          <RadioGroup
            value={viewModeOptions.find((it) => it.value === viewMode)}
            onChange={(e) => setViewMode(e?.value!!)}
            options={viewModeOptions}
            variant={"horizontal"}
          />
          {viewMode === ViewMode.ALL && (
            <RadioGroup
              $marginTop="5px"
              value={traceTypeOptions.find(
                (it) => it.value === selectedTracesType
              )}
              onChange={(e) => setSelectedTracesType(e?.value!!)}
              options={traceTypeOptions}
              variant={"horizontal"}
            />
          )}
        </>
      )}

      <Section title={"Traces"}>
        <TraceViewerControls
          selectedTraceId={selectedTraceId}
          setSelectedTraceId={setSelectedTraceId}
          traces={filteredTraces}
        />
      </Section>

      {tracesOrigin === TracesOrigin.FAILED && (
        <Section title={"Mm errors cause"}>
          <MapMatchingErrorsCauseSelector
            selectedMapMatchingErrorsCauses={selectedMapMatchingErrorsCauses}
            setSelectedMapMatchingErrorsCauses={
              setSelectedMapMatchingErrorsCauses
            }
            traces={traces}
          />
        </Section>
      )}
      <Section title={"Select orgs"}>
        <OrgsSelector
          featureByOrg={featureByOrg}
          selectedOrgs={selectedOrgs}
          setSelectedOrgs={setSelectedOrgs}
        />
      </Section>
      <Section title={"Regions"}>
        <Checkbox
          label={"Show regions"}
          checked={showRegions}
          style={{ padding: "3px", marginLeft: "10px" }}
          onChange={() => setShowRegions(!showRegions)}
        />
      </Section>
    </ControlsBox>
  );
};
