import React, { useState } from "react";
import styled from "styled-components";
import { Checkbox, Button, Modal } from "tombac";
import { EditIcon } from "tombac-icons";
import {
  FARGO_ORGS,
  NUKUS_ORGS,
  Organization,
} from "../constants/organisation";

const GridDiv = styled.div`
  padding: 20px;
  max-height: 50vh;
  display: grid;
  gap: 10px 20px;
  grid-template-columns: repeat(4, 1fr);
  overflow-y: auto;
`;

const ButtonWrapper = styled.div`
  padding: 20px 0px 20px 0px;
  text-align: center;
  & Button {
    width: 110px;
  }
`;

interface Props {
  organisations: Set<Organization>;
  setOrganisations: (organisations: Set<Organization>) => void;
}

const OrganisationPicker: React.FC<Props> = ({
  organisations,
  setOrganisations,
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const toggleOrganisation = (organisation: Organization) => {
    const newOrganisations = new Set(organisations);
    if (newOrganisations.has(organisation)) {
      newOrganisations.delete(organisation);
    } else {
      newOrganisations.add(organisation);
    }
    setOrganisations(newOrganisations);
  };

  const toggleOrganisations = (orgs: Organization[]) => {
    const newOrganisations = new Set(organisations);
    if (!orgs.some((org) => organisations.has(org))) {
      orgs.forEach((org) => {
        newOrganisations.add(org);
      });
    } else if (orgs.every((org) => organisations.has(org))) {
      orgs.forEach((org) => {
        newOrganisations.delete(org);
      });
    } else {
      orgs.forEach((org) => {
        if (!newOrganisations.has(org)) {
          newOrganisations.add(org);
        }
      });
    }
    setOrganisations(newOrganisations);
  };

  const toggleAll = () => {
    if (organisations.size === 0) {
      setOrganisations(new Set(Object.values(Organization)));
    } else {
      setOrganisations(new Set<Organization>());
    }
  };

  return (
    <>
      <Modal isOpen={isModalOpened}>
        <GridDiv>
          {Object.values(Organization)
            .sort()
            .map((organisation) => (
              <Checkbox
                key={organisation}
                label={organisation}
                checked={organisations.has(organisation)}
                onChange={() => toggleOrganisation(organisation)}
              />
            ))}
        </GridDiv>
        <ButtonWrapper className="Button__wrapper">
          <Button className="Button_OK" onClick={toggleAll}>
            {organisations.size === 0 ? "Select " : "Deselect "} all
          </Button>
          <Button
            marginLeft={3}
            className="Button_OK"
            onClick={() => toggleOrganisations(FARGO_ORGS as Organization[])}
          >
            Fargo
          </Button>
          <Button
            marginRight={3}
            marginLeft={3}
            className="Button_OK"
            onClick={() => toggleOrganisations(NUKUS_ORGS as Organization[])}
          >
            Nukus
          </Button>
          <Button className="Button_OK" onClick={() => setIsModalOpened(false)}>
            OK
          </Button>
        </ButtonWrapper>
      </Modal>

      <Button
        onClick={() => setIsModalOpened(true)}
        prepend={<EditIcon width="12px" />}
        mr={1}
      >
        Orgs ({organisations.size})
      </Button>
    </>
  );
};

export default OrganisationPicker;
