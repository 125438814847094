export enum Organization {
  "unknown" = "unknown",
  "audi" = "audi",
  "autonavi" = "autonavi",
  "basarsoft" = "basarsoft",
  "bestway" = "bestway",
  "bridge" = "bridge",
  "bmw" = "bmw",
  "cartrack" = "cartrack",
  "dtc" = "dtc",
  "easytaxi" = "easytaxi",
  "ecofleet" = "ecofleet",
  "eroad" = "eroad",
  "eurogps" = "eurogps",
  "gdi" = "gdi",
  "gila" = "gila",
  "gps_bulgaria" = "gps_bulgaria",
  "gurtam" = "gurtam",
  "junit" = "junit",
  "hefei-taxi" = "hefei-taxi",
  "home" = "home",
  "idata" = "idata",
  "mobilisis" = "mobilisis",
  "muskoka" = "muskoka",
  "navman" = "navman",
  "safefleet" = "safefleet",
  "sincity" = "sincity",
  "superspring" = "superspring",
  "sygic" = "sygic",
  "taiwan_taxi" = "taiwan_taxi",
  "telenav" = "telenav",
  "ps_toyota_prem" = "ps_toyota_prem",
  "tracker" = "tracker",
  "uber" = "uber",
  "vietmap" = "vietmap",
  "waypoint" = "waypoint",
  "fleet-tomtom" = "fleet-tomtom",
  "audi_tec_tfp" = "audi_tec_tfp",
  "ps_cna" = "ps_cna",
  "daimler" = "daimler",
  "ps_daimler_prem" = "ps_daimler_prem",
  "ps_fiat" = "ps_fiat",
  "ps_fiat_tpeg" = "ps_fiat_tpeg",
  "ps_hyundai" = "ps_hyundai",
  "hk" = "hk",
  "nissan" = "nissan",
  "perseus" = "perseus",
  "ps_psa" = "ps_psa",
  "ps_renault" = "ps_renault",
  "ps_renault_test" = "ps_renault_test",
  "tt_nav" = "tt_nav",
  "vw-entry" = "vw-entry",
  "sports" = "sports",
  "sports-backfill" = "sports-backfill",
  "debug" = "debug",
  "sincity_ids" = "sincity_ids",
  "taxi31300" = "taxi31300",
  "copiloto" = "copiloto",
  "ctrack" = "ctrack",
  "discrete-wl" = "discrete-wl",
  "geosmart" = "geosmart",
  "golsat" = "golsat",
  "mireo" = "mireo",
  "onixsat" = "onixsat",
  "sascar" = "sascar",
  "sgpss" = "sgpss",
  "telogis" = "telogis",
  "transpoco" = "transpoco",
  "webtech" = "webtech",
  "ait" = "ait",
  "forth" = "forth",
  "philgps" = "philgps",
  "itic" = "itic",
  "sensoris-test" = "sensoris-test",
  "cdcom" = "cdcom",
  "nng" = "nng",
  "arity" = "arity",
  "evaluation-1" = "evaluation-1",
  "evaluation-2" = "evaluation-2",
  "evaluation-3" = "evaluation-3",
  "evaluation-4" = "evaluation-4",
  "evaluation-5" = "evaluation-5",
  "evaluation-6" = "evaluation-6",
  "evaluation-7" = "evaluation-7",
  "evaluation-8" = "evaluation-8",
  "evaluation-9" = "evaluation-9",
  "evaluation-10" = "evaluation-10",
  "amigo" = "amigo",
  "roadagram-test" = "roadagram-test",
  "toyota-tmc" = "toyota-tmc",
  "wejo" = "wejo",
  "ndw-nl" = "ndw-nl",
  "muskoka-batch" = "muskoka-batch",
  "daimler-hazard" = "daimler-hazard",
  "moj-taxi" = "moj-taxi",
  "ford-telenav" = "ford-telenav",
  "ford-garmin" = "ford-garmin",
  "gurtam_me" = "gurtam_me",
  "gurtam_ru" = "gurtam_ru",
  "arobs" = "arobs",
  "perseus_alliance_st21" = "perseus_alliance_st21",
  "uffizio" = "uffizio",
  "perseus_volvo_trucks_sem2" = "perseus_volvo_trucks_sem2",
  "ps_volvo_trucks" = "ps_volvo_trucks",
  "toyota_aisin" = "toyota_aisin",
  "stickearn" = "stickearn",
  "formax" = "formax",
  "hk-gen6-hmns" = "hk-gen6-hmns",
}

export const NUKUS_ORGS = [
  "audi_tec_tfp",
  "basarsoft",
  "cartrack",
  "copiloto",
  "ctrack",
  "daimler",
  "discrete-wl",
  "ecofleet",
  "eroad",
  "eurogps",
  "fleet-tomtom",
  "geosmart",
  "gila",
  "golsat",
  "gps_bulgaria",
  "gurtam",
  "gurtam_me",
  "gurtam_ru",
  "home",
  "mireo",
  "mobilisis",
  "muskoka-batch",
  "ps_daimler_prem",
  "ps_psa",
  "ps_renault",
  "safefleet",
  "sascar",
  "sgpss",
  "sincity",
  "sygic",
  "telogis",
  "tracker",
  "transpoco",
  "uber",
  "vw-entry",
  "waypoint",
  "webtech",
];

export const FARGO_ORGS = [
  "audi_tec_tfp",
  "cartrack",
  "ctrack",
  "geosmart",
  "gila",
  "home",
  "ps_daimler_prem",
  "ps_toyota_prem",
  "sygic",
  "telenav",
  "eroad",
  "bestway",
  "ps_fiat",
  "ps_hyundai",
  "muskoka",
  "ps_renault",
  "sincity",
  "easytaxi",
  "uber",
  "ps_psa",
  "gps_bulgaria",
  "eurogps",
  "tt_nav",
  "fleet-tomtom",
  "onixsat",
  "telogis",
  "basarsoft",
  "gurtam_me",
  "gurtam_ru",
  "gurtam",
];
