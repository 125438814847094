import styled from "styled-components";

export const LegendColorContainer = styled.div<{ $color: string }>`
  width: 10px;
  height: 10px;
  padding: 0;
  margin: 0;
  background-color: ${(props) => props.$color};
  margin-right: 10px;
  border-radius: 50%;
`;

export const LegendLineColorContainer = styled.div<{ $color: string }>`
  width: 12px;
  height: 4px;
  margin: 5px;
  background-color: ${(props) => props.$color};
  margin-right: 10px;
  border-radius: 15px;
`;
