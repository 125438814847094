import { GeoJSONSource, Layer, Map } from "mapbox-gl";
import { useEffect } from "react";
import { useMap } from "vector-maps/dist/MapContext";
import { FeatureCollection } from "@turf/turf";
import * as geojson from "geojson";

export const useLayers = (
  id: string,
  layers: Layer[],
  data: FeatureCollection,
  before?: string,
  metrics: boolean = false
) => {
  const map = useMap().map as Map;

  useEffect(() => {
    map.addSource("source-" + id, {
      type: "geojson",
      lineMetrics: metrics,
      data: (data as geojson.FeatureCollection) || {
        type: "FeatureCollection",
        features: [],
      },
    });

    layers.forEach((l) =>
      map.addLayer({ ...l, source: "source-" + id, id: l.id + id }, before)
    );

    return () => {
      layers.forEach((l) => map.removeLayer(l.id + id));
      map.removeSource("source-" + id);
    };
  }, [id, layers, data, metrics, before, map]);

  useEffect(() => {
    (map.getSource("source-" + id) as GeoJSONSource).setData(
      data
        ? (data as geojson.FeatureCollection)
        : { type: "FeatureCollection", features: [] }
    );
  }, [data, map, id]);
};
