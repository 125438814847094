import React, { useState } from "react";
import { Button, Modal, Text } from "tombac";
import { EditIcon } from "tombac-icons";
import { Filter } from "../commonTypes";
import { getCorrectAndActiveFilters } from "../utils/filters";
import FilterOptions from "./FilterOptions";

interface Props {
  filters: Filter[];
  setFilters: (filters: Filter[]) => void;
}

const ManageFilters: React.FC<Props> = ({ filters, setFilters }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const setFilter = (filter: Filter) => {
    const newFilters = [...filters] as Filter[];
    const index = filters.findIndex((f) => f.name === filter.name);
    if (index !== -1) {
      newFilters[index] = filter;
      setFilters(newFilters);
    }
  };

  return (
    <>
      <Modal isOpen={isModalOpened}>
        <div
          style={{
            minWidth: "420px",
            overflow: "hidden",
            overflowY: "scroll",
            maxHeight: "70vh",
          }}
        >
          {filters ? (
            filters.map((filter) => (
              <FilterOptions
                originalFilter={filter}
                setFilter={setFilter}
                key={filter.name}
              />
            ))
          ) : (
            <Text margin={"20px"}>
              No filters was loaded when creating group. Check your backend and
              if it's <span style={{ color: "green" }}>OK</span>, create new
              group.
            </Text>
          )}
        </div>
        <div
          style={{
            textAlign: "center",
            paddingBottom: "20px",
            paddingTop: "10px",
          }}
        >
          <Button
            onClick={() => {
              setIsModalOpened(false);
            }}
          >
            Ok
          </Button>
        </div>
      </Modal>
      <Button
        prepend={<EditIcon width="12px" />}
        onClick={() => {
          setIsModalOpened(true);
        }}
      >
        Filters ({getCorrectAndActiveFilters(filters).length})
      </Button>
    </>
  );
};

export default ManageFilters;
