import { useCallback, useMemo } from "react";
import { TraceGroup } from "../features/TraceGroup";
import { useLocalStorage } from "./useLocalStorage";

/**
 * Store the global app state in localStorage
 *
 * because some fields will be broken if just JSON.stringified
 * custom serialization is done
 */
export const useGroupsState = (
  initialGroups: TraceGroup[]
): [TraceGroup[], (groups: TraceGroup[]) => void] => {
  const serialize = (group: TraceGroup): string => {
    const sanitized = {
      ...group,
      organisations: [...group.organisations],
      date: group.date.getTime(),
    };
    return JSON.stringify(sanitized);
  };
  const deserialize = (group: string): TraceGroup => {
    const sanitized = JSON.parse(group);
    return {
      ...sanitized,
      date: new Date(sanitized.date),
      organisations: new Set(sanitized.organisations),
    } as TraceGroup;
  };
  const [serialized, setSerialized] = useLocalStorage<string[]>(
    `tracegroups-v1`,
    initialGroups.map(serialize)
  );

  const setGroups = useCallback(
    (groups: TraceGroup[]) => {
      setSerialized(groups.map(serialize));
    },
    [setSerialized]
  );

  const groups = useMemo(() => serialized.map(deserialize), [serialized]);

  return [groups, setGroups];
};
