import { useParams, useSearchParams } from "react-router-dom";
import { Feature } from "@turf/turf";
import { useCallback, useEffect, useState } from "react";
import * as turf from "@turf/turf";
import { wktToFeature } from "../utils/geometry";
import { ResponseContentType, api } from "../api";
import { NUKUS_BASE_API_URL } from "../constants/api";
interface SelectedLinkDebugTraceResponse {
  rawTraces: SelectedLinkDebugTrace[];
  mapMatchedTraces: SelectedLinkDebugTrace[];
  finalTraces: SelectedLinkDebugTrace[];
}

export interface SelectedLinkDebugTrace {
  id: string;
  org: string;
  wkt: string;
}

export interface TraceProps {
  id: string;
  org: string;
}

export enum TraceType {
  RAW = "RAW",
  MAP_MATCHED = "MAP_MATCHED",
  FINAL = "FINAL",
}

export const toFeaturesByTracesProps = (
  rawTraces: SelectedLinkDebugTrace[],
  mapMatchedTraces: SelectedLinkDebugTrace[],
  finalTraces: SelectedLinkDebugTrace[]
) => {
  const map = new Map<TraceProps, Feature[]>([]);
  for (let i = 0; i < rawTraces.length; i++) {
    let features = [];

    const trace = rawTraces[i];

    features.push(
      wktToFeature(trace.wkt, {
        id: trace.id,
        org: trace.org,
        type: TraceType.RAW,
      } as turf.Properties)
    );

    const mapMatchedTrace = mapMatchedTraces[i];

    features.push(
      wktToFeature(mapMatchedTrace.wkt, {
        id: trace.id,
        org: trace.org,
        type: TraceType.MAP_MATCHED,
      } as turf.Properties)
    );

    const finalTrace = finalTraces[i];

    features.push(
      wktToFeature(finalTrace.wkt, {
        id: trace.id,
        org: trace.org,
        type: TraceType.FINAL,
      } as turf.Properties)
    );

    const properties = {
      id: trace.id,
      org: trace.org,
    };

    map.set(properties, features);
  }

  return map;
};

export const useSelectedLinkDebugTraces = (
  geometry: Feature[],
  resultType: string,
  limit: number,
  filterByGeometry: boolean
) => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const [traces, setTraces] = useState<Map<TraceProps, Feature[]>>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const load = useCallback(async () => {
    setIsLoading(true);
    try {
      const nukusTraces = (await api(
        `${NUKUS_BASE_API_URL}/analysis/selected-link/${params.id}/result/traces?${searchParams}&resultType=${resultType}&limit=${limit}`,
        {
          method: "POST",
          body: filterByGeometry ? JSON.stringify(geometry) : undefined,
          headers: {
            "Content-Type": "application/json",
          },
        },
        ResponseContentType.JSON
      )) as SelectedLinkDebugTraceResponse;

      console.log(nukusTraces);

      const featuresByTracesProps = toFeaturesByTracesProps(
        nukusTraces.rawTraces,
        nukusTraces.mapMatchedTraces,
        nukusTraces.finalTraces
      );

      setTraces(featuresByTracesProps);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      setIsError(true);
    }
  }, [filterByGeometry, geometry, limit, params.id, resultType, searchParams]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    featuresByTraceProps: traces,
    isLoading,
    isError,
  };
};
