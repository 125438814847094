import React, { useState } from "react";
import { Box, Button, Heading } from "tombac";
import styled from "styled-components";
import { AddIcon } from "tombac-icons";
import { makeTraceGroup, TraceGroupSection } from "./TraceGroup";
import { useGroupsState } from "../hooks/useGroupsState";
import ClearGroupsButton from "./ClearGroupsButton";
import { useFilters } from "../hooks/useFilters";
import { Filter } from "../commonTypes";
import { TraceSelector } from "./TraceSelector";
import TraceInfo from "./TraceInfo";
import { useNavigate } from "react-router-dom";

const WhiteBox = styled(Box)`
  background: white;
`;

const ControlsBox = styled(WhiteBox)`
  display: flex;
  flex-direction: column;
  margin: 10px;

  & .DatePicker {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const ControlsContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  margin: 10px;
  display: flex;
  flex-direction: column;
  z-index: 15;
  font-size: 14px;
  width: 420px;
  max-height: 90vh;
`;

export interface TracePage {
  id: string;
  timeRange?: [number, number];
}

export const TracesPage = () => {
  const [groups, setGroups] = useGroupsState([]);
  const [traceSelection, setTraceSelection] = useState<TracePage | undefined>(
    undefined
  );
  const navigate = useNavigate();

  const routeChange = () => {
    const path = `/nukus-debug/flow-matrix/csv`;
    navigate(path);
  };

  const filters = useFilters();

  const setSelectedTraceId = (id: string | undefined) =>
    setTraceSelection(id === undefined ? undefined : { id });

  return (
    <>
      <ControlsContainer>
        <Button
          variant="accent"
          $margin="10px"
          $marginBottom="0"
          onClick={routeChange}
        >
          Nukus debug
        </Button>
        <ControlsBox>
          <Box display="flex" alignItems="center" p={2}>
            <Heading level={3} mr="auto">
              Groups
            </Heading>
            <ClearGroupsButton clearGroups={() => setGroups([])} />
            <Button
              ml={1}
              variant="accent"
              prepend={<AddIcon width="14px" />}
              busy={filters.isLoading}
              onClick={() =>
                setGroups([
                  ...groups,
                  makeTraceGroup(filters.collection as Filter[]),
                ])
              }
            >
              New
            </Button>
          </Box>

          {groups.map((group, i) => (
            <TraceGroupSection
              key={group.id}
              group={group}
              onDelete={() => {
                setGroups(groups.filter((_, index) => index !== i));
              }}
              onChange={(newGroup) => {
                const copy = [...groups];
                copy[i] = newGroup;
                setGroups(copy);
              }}
              traceSelection={traceSelection}
            />
          ))}
        </ControlsBox>
      </ControlsContainer>
      <TraceSelector groups={groups} setSelectedTraceId={setSelectedTraceId} />
      {traceSelection !== undefined && (
        <TraceInfo
          traceId={traceSelection?.id}
          setTraceSelection={setTraceSelection}
        />
      )}
    </>
  );
};
