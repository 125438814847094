import { Organization } from "../constants/organisation";
import { Feature, FeatureCollection, geomEach, LineString } from "@turf/turf";
import { useEffect, useState } from "react";
import { api } from "../api";
import { Filter } from "../commonTypes";
import { getCorrectAndActiveFilters } from "../utils/filters";
import { BASE_API_URL } from "../constants/api";

const formatNumber = (number: number): string =>
  number < 10 ? `0${number}` : number.toString();

const formatDate = (date: Date) =>
  `${date.getUTCFullYear()}-${formatNumber(
    date.getUTCMonth() + 1
  )}-${formatNumber(date.getUTCDate())}`;

const allTraces = new Map<number, Feature[]>();
export const getTracesByIds = (ids: string[]) => {
  const traces: Feature[] = [];

  for (const group of allTraces.values()) {
    group.forEach((it: any) => {
      if (ids.includes(it.properties?.id)) {
        traces.push(it);
      }
    });
  }

  return traces;
};

const getFirstGeometry = (geojson: any) => {
  let firstGeometry: any;
  geomEach(geojson, (geometry) => {
    if (firstGeometry !== undefined) return;
    firstGeometry = geometry;
  });
  return firstGeometry;
};

export const useTraces = (
  id: number,
  geometry: any,
  date: Date,
  orgs: Set<Organization>,
  filters: Filter[],
  limit: number
) => {
  const [traces, setTraces] =
    useState<
      FeatureCollection<
        LineString,
        { id: string; timestamps: number[]; org: string }
      >
    >();
  const [isLoading, setIsLoading] = useState(false);

  const load = async () => {
    setIsLoading(true);
    try {
      const collection = await api(
        `${BASE_API_URL}/traces?code=BT66SKPmf1nVjsmDXxZDf3EJYisjRiMelAIm/sf5sqWC5liyt9e7XA==`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            date: formatDate(date),
            geometry: JSON.stringify(getFirstGeometry(geometry)),
            orgs: Array.from(orgs),
            queryConfig: {
              settings: getCorrectAndActiveFilters(filters),
            },
            limit,
          }),
        }
      );
      setTraces(collection);
      allTraces.set(id, collection.features);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    return () => {
      allTraces.delete(id);
    };
  }, [id]);

  const clear = () => {
    setTraces(undefined);
    allTraces.delete(id);
  };

  return {
    load,
    collection: traces,
    isLoading,
    clear,
  };
};
