import { center, Feature, FeatureCollection, Geometry } from "@turf/turf";
import React, { useEffect, useMemo, useState } from "react";
import { FINISH_ICON_NAME } from "../constants/constants";
import { useLayers } from "../hooks/useLayers";
import { getColoredTrace } from "../utils/traces";
import finishIcon from "tombac-icons/svg/finish.svg";
import { useMap } from "vector-maps/dist/MapContext";
import Popup from "vector-maps/dist/features/Popup";
import { Map, MapMouseEvent } from "mapbox-gl";
import {
  chosenTraceFinishIconLayers,
  chosenTraceGapsLayers,
  chosenTraceLayers,
  hoveredTraceFinishIconLayers,
  hoveredTraceGapsLayers,
  hoveredTraceLayers,
} from "../utils/layers";

interface Props {
  sourceId: string;
  trace?: Feature;
  hovered?: boolean;
}

const ColoredTrace: React.FC<Props> = ({ sourceId, trace, hovered }) => {
  const map = useMap().map as Map;
  const [hoveredSegment, setHoveredSegment] = useState<
    { lat: number; lng: number; properties: any } | undefined
  >(undefined);

  const getFinishCoords = (trace?: FeatureCollection): number[] => {
    if (trace) {
      const maxTime = Math.max(
        ...trace.features.map((feature) => feature.properties?.time)
      );

      return (
        trace.features.find((feature) => feature.properties?.time === maxTime)
          ?.geometry as Geometry
      )?.coordinates[1] as number[];
    } else return [] as number[];
  };

  const { lines, gaps } = useMemo(() => getColoredTrace(trace), [trace]);
  const traceFinishIcon = useMemo(() => {
    return {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: getFinishCoords(lines),
          },
          properties: {},
        },
      ],
    } as FeatureCollection;
  }, [lines]);

  useEffect(() => {
    const onClick = (e: MapMouseEvent) => {
      const mapZoom = map.getZoom();
      const bbox = [
        [e.point.x - mapZoom, e.point.y - mapZoom],
        [e.point.x + mapZoom, e.point.y + mapZoom],
      ];
      const features = map.queryRenderedFeatures(bbox as any, {
        layers: [chosenTraceLayers[0].id + sourceId],
      });

      const segment = features[0];

      if (segment) {
        setHoveredSegment((hoveredSegment: any) => {
          if (
            hoveredSegment === undefined ||
            hoveredSegment.properties !== segment.properties
          ) {
            const centerPoint = center(segment.geometry as any);
            return {
              lng: centerPoint.geometry!.coordinates[0],
              lat: centerPoint.geometry!.coordinates[1],
              properties: segment.properties,
            };
          }
          return hoveredSegment;
        });
      } else {
        setHoveredSegment(undefined);
      }
    };

    map.on("mousemove", onClick);

    return () => {
      map.off("mousemove", onClick);
    };
  }, [map, sourceId]);

  useEffect(() => {
    let img = new Image(20, 20);
    img.width = 36;
    img.height = 52;
    img.onload = () => {
      if (!map.hasImage(FINISH_ICON_NAME)) {
        map.addImage(FINISH_ICON_NAME, img, { sdf: true });
      }
    };
    img.src = finishIcon;
  }, [map]);

  useLayers(
    sourceId,
    hovered ? hoveredTraceLayers : chosenTraceLayers,
    lines as FeatureCollection
  );
  useLayers(
    `${sourceId}-gaps`,
    hovered ? hoveredTraceGapsLayers : chosenTraceGapsLayers,
    gaps as FeatureCollection
  );
  useLayers(
    `${sourceId}-finish`,
    hovered ? hoveredTraceFinishIconLayers : chosenTraceFinishIconLayers,
    traceFinishIcon
  );

  const hoverProps = hoveredSegment?.properties;

  return (
    <>
      {hoveredSegment !== undefined && (
        <Popup
          content={
            <>
              Time: {new Date(hoverProps.time).toISOString()}
              <br />
              Speed: {hoverProps.speed.toFixed(2)}
              <br />
              Duration (s): {hoverProps.durationInSeconds}
              <br />
              Distance: {hoverProps.distance.toFixed(2)}
            </>
          }
          offset={[0, 5]}
          anchor="top"
          lat={hoveredSegment.lat}
          lng={hoveredSegment.lng}
        />
      )}
    </>
  );
};

export default ColoredTrace;
