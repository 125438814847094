import { Feature } from "@turf/turf";
import React from "react";
import styled from "styled-components";
import { Box, Button, Text, tombac } from "tombac";
import { BackIcon, ChevronRightIcon } from "tombac-icons";

const TracePartEntryContainer = styled.div<{ active?: boolean }>`
  height: ${tombac.space(3)};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: default;
  font-family: ${tombac.fontFamily};
  font-size: 16px;
  font-weight: 500;
  padding: ${tombac.space(1)} ${tombac.space(2)};
  background-color: ${(p) =>
    p.active ? tombac.color("neutral", 900) : "none"};
  color: ${(p) =>
    p.active ? tombac.color("neutral", 100) : tombac.color("neutral", 900)};

  :hover {
    background-color: ${(p) =>
      p.active ? tombac.color("neutral", 700) : tombac.color("neutral", 400)};
  }

  margin-right: 4px;
`;

const TracePartContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectorContainer = styled.div`
  position: fixed;
  z-index: 15;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
`;

const PartsContainer = styled.div`
  display: flex;
  gap: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  align-items: flex-start;
`;

const SelectAllButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

interface Props {
  selectedMapMatchedPartId?: number;
  setSelectedMapMatchedPartId: (id?: number) => void;
  mapMatchedTraceParts: Feature[];
}

export const MapMatchedTracePartsSelector = ({
  selectedMapMatchedPartId,
  setSelectedMapMatchedPartId,
  mapMatchedTraceParts,
}: Props) => {
  const shouldBeActive = (partId: number) => {
    if (selectedMapMatchedPartId === undefined) return true;

    return partId === selectedMapMatchedPartId;
  };

  const handleNextPart = () => {
    if (
      selectedMapMatchedPartId !== undefined &&
      selectedMapMatchedPartId < mapMatchedTraceParts.length - 1
    ) {
      setSelectedMapMatchedPartId(selectedMapMatchedPartId + 1);
    }

    if (selectedMapMatchedPartId === undefined) {
      setSelectedMapMatchedPartId(0);
    }
  };

  const handlePrevPart = () => {
    if (
      selectedMapMatchedPartId !== undefined &&
      selectedMapMatchedPartId > 0
    ) {
      setSelectedMapMatchedPartId(selectedMapMatchedPartId - 1);
    }

    if (selectedMapMatchedPartId === undefined) {
      setSelectedMapMatchedPartId(mapMatchedTraceParts.length - 1);
    }
  };

  return (
    <SelectorContainer>
      <PartsContainer>
        <Button $width="12px" shape="circle" size="s" onClick={handlePrevPart}>
          <BackIcon />
        </Button>
        {mapMatchedTraceParts.map((tracePart) => {
          const partId = tracePart.properties?.partId;
          return (
            <>
              <TracePartContainer>
                <TracePartEntryContainer
                  active={shouldBeActive(partId)}
                  onClick={() => setSelectedMapMatchedPartId(partId)}
                >
                  Part {partId + 1}
                </TracePartEntryContainer>
                <Text $mt="2px" size="s" $color="#ff0000">
                  {tracePart.properties?.partFailCause}
                </Text>
              </TracePartContainer>
              {partId !== mapMatchedTraceParts.length - 1 && (
                <Box $pt="5px">
                  <ChevronRightIcon />
                </Box>
              )}
            </>
          );
        })}
        <Button $width="12px" shape="circle" size="s" onClick={handleNextPart}>
          <BackIcon rotate={180} />
        </Button>
      </PartsContainer>
      <SelectAllButtonContainer>
        <Button
          onClick={() => setSelectedMapMatchedPartId(undefined)}
          size="xs"
        >
          Select all
        </Button>
      </SelectAllButtonContainer>
    </SelectorContainer>
  );
};
