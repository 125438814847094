export interface FlowMatrixDebugTrace extends FlowMatrixDebugTraceProps {
  rawGeometry: string;
  rawTrimmedForMapMatchingGeometry?: string;
  mapMatchedGeometry?: string;
}

export interface FlowMatrixDebugTraceProps {
  id: string;
  avgSpeedMs: number;
  lengthInMeters: number;
  org: string;
  traceType?: FlowMatrixDebugTraceType;
  failCause?: (string | undefined)[];
}

export enum FlowMatrixDebugTraceType {
  RAW = "RAW",
  MAP_MATCHED = "MAP_MATCHED",
  TRIMMED_FOR_MAP_MATCHING = "TRIMMED_FOR_MAP_MATCHING",
}

export enum ViewMode {
  ALL = "ALL",
  SINGLE = "SINGLE",
}

export enum TracesOrigin {
  FAILED = "FAILED",
  ANALYSIS = "ANALYSIS",
}

export interface VisibleLines {
  raw: boolean;
  mapMatched: boolean;
  rawTrimmedForMapMatching: boolean;
}
