import "./App.css";
import React from "react";
import Map from "vector-maps/dist/Map";
import ReactModal from "react-modal";
import { ToastsProvider, TombacApp } from "tombac";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { TracesPage } from "./features/TracesPage";
import { DebugCsvPage } from "./features/NukusDebug/FlowMatrix/CsvUpload/DebugCsvPage";
import { FlowMatrixDebugPage } from "./features/NukusDebug/FlowMatrix/FlowMatrixDebugPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { SelectedLinkDebugPage } from "./features/NukusDebug/SelectedLink/SelectedLinkDebugPage";

ReactModal.setAppElement("#root");

export interface TraceSelection {
  id: string;
  timeRange?: [number, number];
}

const App = () => {
  return (
    <div className="App">
      <TombacApp theme={{ settings: { modalZIndex: 16 } }}>
        <ToastsProvider zIndex={10}>
          <Map
            className="Map"
            mapProps={{ center: [0, 0] }}
            fitPadding={20}
            mapStyle={"https://d3m5zl3ljbpwma.cloudfront.net/neon.json"}
          >
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<TracesPage />} />
                <Route
                  path="/nukus-debug/flow-matrix/csv"
                  element={<DebugCsvPage />}
                />
                <Route
                  path="/nukus-debug/:id"
                  element={<FlowMatrixDebugPage />}
                />
                <Route
                  path="/nukus-debug/selected-link/:id"
                  element={<SelectedLinkDebugPage />}
                />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </BrowserRouter>
          </Map>
        </ToastsProvider>
      </TombacApp>
    </div>
  );
};

export default App;
