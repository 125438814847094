import React, { useState } from "react";
import styled from "styled-components";
import { Button, Caption, Input, Label } from "tombac";
import { TraceProps } from "../../../hooks/useSelectedLinkDebugTraces";
import { BackIcon } from "tombac-icons";

export const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 5px;
`;

export const ScrollableList = styled.ul`
  overflow: hidden;
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
  margin: 0;
  left: 0;
  height: 75vh;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 5px;
`;

interface Props {
  setSelectedTraceProps: (tracesId: TraceProps) => void;
  selectedTraceProps: TraceProps;
  tracesProps: TraceProps[];
}

export const TraceSelector = ({
  setSelectedTraceProps,
  selectedTraceProps,
  tracesProps,
}: Props) => {
  const [searchPattern, setSearchPattern] = useState<string>("");
  const [selectedTraceIndex, setSelectedTraceIndex] = useState<number>(0);

  const handleNextTrace = () => {
    if (selectedTraceIndex < tracesProps.length - 1) {
      const nextProps = tracesProps[selectedTraceIndex + 1];
      setSelectedTraceProps(nextProps);
      setSelectedTraceIndex(selectedTraceIndex + 1);
    }
  };

  const handlePrevTrace = () => {
    if (selectedTraceIndex > 0) {
      const prevProps = tracesProps[selectedTraceIndex - 1];
      setSelectedTraceProps(prevProps);
      setSelectedTraceIndex(selectedTraceIndex - 1);
    }
  };

  return (
    <SelectorContainer>
      <ControlsContainer>
        <Input
          onChange={(e) => setSearchPattern(e.target.value)}
          $width={"100%"}
          placeholder="Search by id..."
        />{" "}
        <Button $width="12px" shape="circle" size="s" onClick={handleNextTrace}>
          <BackIcon rotate={270} />
        </Button>
        <Button $width="12px" shape="circle" size="s" onClick={handlePrevTrace}>
          <BackIcon rotate={90} />
        </Button>
      </ControlsContainer>
      <ScrollableList>
        {tracesProps
          .filter((it) => it.id.includes(searchPattern))
          .map((prop, index) => (
            <li key={index} onClick={() => setSelectedTraceProps(prop)}>
              <Button
                $marginBottom={"1px"}
                $width={"100%"}
                size="s"
                style={{
                  fontSize: "11px",
                  justifyContent: "flex-start",
                  height: "auto",
                }}
                onClick={() => setSelectedTraceProps(prop)}
                disabled={selectedTraceProps === prop}
              >
                <ItemContainer>
                  <Label>{prop.id}</Label> <Caption>Org: {prop.org}</Caption>
                </ItemContainer>
              </Button>
            </li>
          ))}
      </ScrollableList>
    </SelectorContainer>
  );
};
