import { SpinnerIcon } from "tombac-icons";
import { useSelectedLinkDebugTraces } from "../../../hooks/useSelectedLinkDebugTraces";
import { LoadingContainer } from "../../common.style";
import React from "react";
import { Alert, useToasts } from "tombac";
import { Feature, featureCollection } from "@turf/turf";
import { SelectedLinkDebugVisualization } from "./SelectedLinkDebugVisualization";
import { GeometryLayer } from "../../GeometryLayer";

interface Props {
  geometry: Feature[];
  setEditorOpen: (editorOpen: boolean) => void;
  resultType: string;
  limit: number;
  filterByGeometry: boolean;
}

export const SelectedLinkDebugResults = ({
  geometry,
  setEditorOpen,
  resultType,
  limit,
  filterByGeometry,
}: Props) => {
  const { addToast } = useToasts();
  const { featuresByTraceProps, isLoading, isError } =
    useSelectedLinkDebugTraces(geometry, resultType, limit, filterByGeometry);

  if (isLoading) {
    return (
      <LoadingContainer>
        <SpinnerIcon size={7} color="#ffffff" spin={true} />
      </LoadingContainer>
    );
  }

  if (isError || !featuresByTraceProps) {
    return (
      <LoadingContainer>
        <Alert $width="100%" title="Danger!" variant="danger">
          Unable to fetch traces
        </Alert>
      </LoadingContainer>
    );
  }

  if (featuresByTraceProps.size === 0) {
    setEditorOpen(true);
    addToast("Traces not found for given geometry", "alert", {
      autoDismiss: true,
      autoDismissDelay: 7000,
    });
    return null;
  }

  return (
    <>
      {geometry.length > 0 && (
        <GeometryLayer geometry={featureCollection(geometry)} />
      )}
      <SelectedLinkDebugVisualization
        setEditorOpen={setEditorOpen}
        featuresByTraceProps={featuresByTraceProps}
      />
    </>
  );
};
