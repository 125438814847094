import { FeatureCollection } from "@turf/turf";
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { Box, Button, Checkbox } from "tombac";
import { FlowMatrixDebugTraceProps } from "../../../api/model/flowMatrixDebug";

const WhiteBox = styled(Box)`
  background: white;
`;

const SelectorBox = styled(WhiteBox)`
  display: flex;
  flex-direction: column;
  margin: 10px;
  max-height: 70vh;
  overflow: auto;
`;
interface Props {
  selectedMapMatchingErrorsCauses?: Set<string>;
  setSelectedMapMatchingErrorsCauses: (causes: Set<string>) => void;
  traces: FeatureCollection<any, FlowMatrixDebugTraceProps>;
}

const countByMapMatchingErrorsCauses = (
  traces: FeatureCollection<any, FlowMatrixDebugTraceProps>
): Map<string, number> => {
  const mapMatchingErrorsCauses = new Map<string, number>();
  traces.features.forEach((trace) => {
    if (trace.properties?.failCause) {
      [...new Set(trace.properties.failCause)]
        .filter((it) => it !== undefined)
        .forEach((error) => {
          const causeEntry = mapMatchingErrorsCauses.get(error);
          if (causeEntry) {
            mapMatchingErrorsCauses.set(error, causeEntry + 1);
          } else {
            mapMatchingErrorsCauses.set(error, 1);
          }
        });
    }
  });
  return mapMatchingErrorsCauses;
};

export const MapMatchingErrorsCauseSelector = ({
  selectedMapMatchingErrorsCauses,
  setSelectedMapMatchingErrorsCauses,
  traces,
}: Props) => {
  const mapMatchingErrorsCauses: Map<string, number> = useMemo(() => {
    return countByMapMatchingErrorsCauses(traces);
  }, [traces]);

  useEffect(() => {
    if (selectedMapMatchingErrorsCauses === undefined) {
      setSelectedMapMatchingErrorsCauses(
        new Set(countByMapMatchingErrorsCauses(traces).keys())
      );
    }
  }, [
    selectedMapMatchingErrorsCauses,
    setSelectedMapMatchingErrorsCauses,
    traces,
  ]);

  const toggleCause = (cause: string) => {
    const newCauses = new Set(selectedMapMatchingErrorsCauses);
    if (newCauses.has(cause)) {
      newCauses.delete(cause);
    } else {
      newCauses.add(cause);
    }
    setSelectedMapMatchingErrorsCauses(newCauses);
  };

  const deselectAll = () => {
    setSelectedMapMatchingErrorsCauses(new Set());
  };

  const selectAll = () => {
    setSelectedMapMatchingErrorsCauses(
      new Set(selectedMapMatchingErrorsCauses?.keys())
    );
  };

  return (
    <>
      <SelectorBox>
        {Array.from(mapMatchingErrorsCauses).map(([cause, tracesNumber]) => (
          <Checkbox
            key={cause}
            label={`${cause} (${tracesNumber})`}
            checked={selectedMapMatchingErrorsCauses?.has(cause)}
            style={{ padding: "3px" }}
            onChange={() => toggleCause(cause)}
          />
        ))}
        <Button onClick={deselectAll}>Deselect all</Button>
        <Button style={{ marginTop: "10px" }} onClick={selectAll}>
          Select all
        </Button>
      </SelectorBox>
    </>
  );
};
