export enum ResponseContentType {
  JSON = "json",
  BLOB = "blob",
}

export async function api(
  url: string,
  config: {},
  responseContent: ResponseContentType = ResponseContentType.JSON
): Promise<any> {
  let response = await fetch(`${url}`, {
    ...config,
    credentials: "include",
  });

  if (!response.ok) {
    const err = await response.json();
    throw new Error(err.message);
  }
  if (responseContent === ResponseContentType.JSON) {
    return await response.json();
  } else {
    return await response.blob();
  }
}

export async function get(
  url: string,
  responseContent: ResponseContentType = ResponseContentType.JSON
) {
  return api(
    url,
    {
      method: "GET",
    },
    responseContent
  );
}
