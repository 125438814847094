import React from "react";
import { Input, Label } from "tombac";
import { pascalCaseToHumanFriendly } from "../utils/string";

interface Props {
  filterActive: boolean;
  index: number;
  value: any;
  name: string;
  editParam: (value: any, index: number) => void;
}

const NumericFilterParam = ({
  filterActive,
  name,
  value,
  editParam,
  index,
}: Props) => {
  return (
    <div style={{ marginTop: "10px" }}>
      <Label htmlFor={`${name}-input`}>{pascalCaseToHumanFriendly(name)}</Label>
      <Input
        invalid={filterActive && !value}
        disabled={!filterActive}
        $width={"100%"}
        type={"number"}
        id={`${name}-input`}
        placeholder={name}
        value={value ? value : ("" as number | string)}
        onChange={(e) => editParam(e.target.value, index)}
      />
    </div>
  );
};

export default NumericFilterParam;
