import { buffer, lineString } from "@turf/turf";
import { useAuth } from "legoland-sdk";
import React, { useState } from "react";
import { Button, FormGroup, Input } from "tombac";
import { Section } from "./Section";

const fetchGeometry = async (type: string, id: string, apiKey: string) => {
  const res = await fetch(
    `https://api.tomtom.com/traffic/trafficstats/${type}/1/${id}?key=${apiKey}`,
    {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const analysis = await res.json();

  let geometry: any;

  if (analysis.routes) {
    const route = analysis.routes[0];
    const line = lineString(
      route.routingPoints.map((it: any) => [it.longitude, it.latitude])
    );

    const bline = buffer(line, 20, { units: "meters" });

    geometry = bline;
  }

  if (analysis.network) {
    geometry = analysis.network.geometry;
  }

  return geometry;
};

const getInfoFromURL = (url: string) => {
  const urlRegex = /details\/([A-Z]+)\/([0-9]+)/.exec(url);

  if (urlRegex === null) {
    return;
  }

  const typeMap = {
    CAA: "areaanalysis",
    CTT: "routeanalysis",
    CPC: "trafficdensity",
  };

  // @ts-ignore
  return { type: typeMap[urlRegex[1]], id: urlRegex[2] };
};

export function FargoLoader({ onGeometry }: { onGeometry: (g: any) => void }) {
  const { me } = useAuth();
  const apiKey = me?.products.find((it) => it.product === "ts")?.apiKey;
  const [report, setReport] = useState<any | undefined>();

  return (
    <Section title="Import from traffic stats">
      <FormGroup label="Report URL">
        <Input
          placeholder="Report URL"
          onChange={(e) => {
            const report = getInfoFromURL(e.target.value);
            setReport(report);
          }}
        ></Input>
      </FormGroup>
      <Button
        mt={1}
        onClick={async () => {
          if (report && apiKey) {
            const geometry = await fetchGeometry(
              report.type,
              report.id,
              apiKey
            );
            onGeometry(geometry);
          }
        }}
      >
        Fetch geometry
      </Button>
    </Section>
  );
}
