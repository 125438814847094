import React, { useEffect, useMemo, useState } from "react";
import { useLayers } from "../hooks/useLayers";
import { useMap } from "vector-maps/dist/MapContext";
import { generateLinePattern } from "./linePattern";

import { EventData, Layer, Map, MapMouseEvent, Popup } from "mapbox-gl";
import { FeatureCollection } from "@turf/turf";

interface Props {
  traces: FeatureCollection;
  showTraces: boolean;
  showPoints: boolean;
  color: string;
  layerId?: number;
}

export const TracesLayer: React.FC<Props> = ({
  traces,
  showTraces,
  showPoints,
  color,
  layerId,
}) => {
  const [id] = useState(layerId ?? (Math.random() * 1000) | 0);
  const map = useMap().map as Map;

  const layers = useMemo<Layer[]>(
    () => [
      {
        id: "traces-lines",
        type: "line",
        paint: {
          "line-width": 1,
          "line-color": color,
          "line-opacity": 0.2,
        },
        layout: {
          "line-cap": "butt",
          "line-join": "bevel",
        },
      },
      {
        id: "traces-points",
        type: "circle",
        paint: {
          "circle-radius": {
            base: 1.5,
            stops: [
              [12, 1],
              [22, 15],
            ],
          },
          "circle-color": color,
          "circle-opacity": 0.2,
        },
      },
    ],
    [color]
  );

  useEffect(() => {
    map.addImage(
      "incident-pattern" + id,
      generateLinePattern([50, 50, 200], [205, 205, 255])
    );

    return () => {
      map.removeImage("incident-pattern" + id);
    };
  }, [map, id]);

  useLayers(String(id), layers, traces, "Service road icon1");

  useEffect(() => {
    map.setLayoutProperty(
      "traces-lines" + id,
      "visibility",
      showTraces ? "visible" : "none"
    );
  }, [showTraces, map, id]);

  useEffect(() => {
    map.setLayoutProperty(
      "traces-points" + id,
      "visibility",
      showPoints ? "visible" : "none"
    );
  }, [showPoints, map, id]);

  useEffect(() => {
    const popup = new Popup();
    const segmentInfo = (e: MapMouseEvent & EventData) =>
      popup
        .setLngLat(e.lngLat)
        .setHTML(
          `<div style="text-align: left; margin-top: 5px;">
        <div><b>Time: </b>${e.features[0].properties.durationInSeconds} s</div>
        <div><b>Distance: </b>${e.features[0].properties.distance.toFixed(
          1
        )} m</div>
        <div><b>Speed: </b>${e.features[0].properties.speed.toFixed(
          2
        )} km/h</div>
        </div>`
        )
        .addTo(map);

    map.on("click", "traces-lines-highlighted" + id, segmentInfo);

    return () => {
      popup.remove();
      map.off("click", "traces-lines-highlighted" + id, segmentInfo);
    };
  }, [id, map, traces]);

  return null;
};
