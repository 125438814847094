import { featureCollection, FeatureCollection, Geometry } from "@turf/turf";
import { useCallback, useEffect, useState } from "react";
import { api, ResponseContentType } from "../api";
import { BASE_API_URL, DELEMONT_FUNCTION_CODE } from "../constants/api";
import { useParams, useSearchParams } from "react-router-dom";
import { wktToFeature } from "../utils/geometry";

interface LinkResponse {
  link: string;
  buffer: string;
}

export const useLink = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [link, setLink] = useState<FeatureCollection<Geometry>>();
  const [buffer, setBuffer] = useState<FeatureCollection<Geometry>>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const load = useCallback(async () => {
    setIsLoading(true);
    try {
      const linkResponse = (await api(
        `${BASE_API_URL}/selected-link/${params.id}/link?${searchParams}&code=${DELEMONT_FUNCTION_CODE}`,
        {
          method: "GET",
        },
        ResponseContentType.JSON
      )) as LinkResponse;

      setLink(featureCollection([wktToFeature(linkResponse.link)]));
      setBuffer(featureCollection([wktToFeature(linkResponse.buffer)]));
    } catch (e) {
      console.error(e);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [params.id, searchParams]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    link,
    buffer,
    isLoading,
    isError,
  };
};
