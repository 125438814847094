import { FINISH_ICON_NAME } from "../constants/constants";

export const FIRST_TRACE_REPORT_COLOR = "#FFD700";
export const LAST_TRACE_REPORT_COLOR = "#D3D3D3";

export const tracesLayers: mapboxgl.Layer[] = [
  {
    id: "traces-lines",
    type: "line",
    paint: {
      "line-width": 1,
      "line-color": "rgba(97,173,224,0.4)",
    },
    layout: {
      "line-cap": "butt",
      "line-join": "bevel",
    },
  },
  {
    id: "traces-points",
    type: "circle",
    paint: {
      "circle-radius": {
        base: 1.5,
        stops: [
          [12, 1],
          [22, 15],
        ],
      },
      "circle-color": "rgba(97,173,224,0.6)",
    },
  },
];

export const chosenTraceLayers: mapboxgl.Layer[] = [
  {
    id: "chosen-trace-lines-highlighted",
    type: "line",
    paint: {
      "line-width": [
        "interpolate",
        ["exponential", 0.5],
        ["zoom"],
        10,
        3,
        15,
        4,
        22,
        7,
      ],
      "line-color": ["get", "color"],
    },
  },
  {
    id: "hovered-trace-points-highlighted",
    type: "circle",
    paint: {
      "circle-radius": {
        base: 6,
        stops: [
          [10, 4],
          [13, 7],
          [20, 9],
        ],
      },
      "circle-color": ["get", "color"],
      "circle-stroke-width": 0.5,
      "circle-stroke-color": "#000000",
    },
  },
];

export const traceStartPointLayer: mapboxgl.Layer[] = [
  {
    id: "trace-start-point",
    type: "circle",
    paint: {
      "circle-radius": {
        base: 7,
        stops: [
          [10, 5],
          [13, 6],
          [20, 8],
        ],
      },
      "circle-color": FIRST_TRACE_REPORT_COLOR,
      "circle-stroke-width": 0.5,
      "circle-stroke-color": "#000000",
    },
  },
];

export const traceEndPointLayer: mapboxgl.Layer[] = [
  {
    id: "trace-start-point",
    type: "circle",
    paint: {
      "circle-radius": {
        base: 7,
        stops: [
          [10, 5],
          [15, 6],
          [20, 8],
        ],
      },
      "circle-color": LAST_TRACE_REPORT_COLOR,
      "circle-stroke-width": 0.5,
      "circle-stroke-color": "#000000",
    },
  },
];

export const hoveredTraceLayers: mapboxgl.Layer[] = [
  {
    id: "hovered-trace-lines-highlighted",
    type: "line",
    paint: {
      "line-width": 2,
      "line-color": ["get", "color"],
    },
  },
  {
    id: "hovered-trace-points-highlighted",
    type: "circle",
    paint: {
      "circle-radius": {
        base: 1.5,
        stops: [
          [12, 1],
          [22, 15],
        ],
      },
      "circle-color": ["get", "color"],
    },
  },
];

export const chosenTraceGapsLayers: mapboxgl.Layer[] = [
  {
    id: "chosen-trace-gaps",
    type: "line",
    paint: {
      "line-width": [
        "interpolate",
        ["exponential", 0.5],
        ["zoom"],
        10,
        3,
        15,
        4,
        22,
        7,
      ],
      "line-color": ["get", "color"],
    },
  },
];

export const hoveredTraceGapsLayers: mapboxgl.Layer[] = [
  {
    id: "hovered-trace-gaps",
    type: "line",
    paint: {
      "line-width": 5,
      "line-opacity": 0.5,
      "line-color": ["get", "color"],
    },
  },
];

export const chosenTraceFinishIconLayers: mapboxgl.Layer[] = [
  {
    id: "chosen-trace-finish-icon-layer",
    type: "symbol",
    layout: {
      "icon-image": FINISH_ICON_NAME,
      "icon-size": 1,
    },
    paint: {
      "icon-color": "white",
      "icon-translate": [16, -26],
    },
  },
];

export const hoveredTraceFinishIconLayers: mapboxgl.Layer[] = [
  {
    id: "hovered-trace-finish-icon-layer",
    type: "symbol",
    layout: {
      "icon-image": FINISH_ICON_NAME,
      "icon-size": 1,
    },
    paint: {
      "icon-color": "white",
      "icon-opacity": 0.5,
      "icon-translate": [16, -26],
    },
  },
];
