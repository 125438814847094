import { useEffect, useMemo, useState } from "react";
import { useMap } from "vector-maps/dist/MapContext";
import { Layer, Map } from "mapbox-gl";
import {
  Feature,
  featureCollection,
  Geometry,
  GeometryCollection,
  lineString,
  multiLineString,
  MultiLineString,
  point,
} from "@turf/turf";
import { useLayers } from "../../../hooks/useLayers";
import arrowTriangleAlpha from "../arrow-triangle-alpha.svg";
import {
  FlowMatrixDebugTraceProps,
  VisibleLines,
} from "../../../api/model/flowMatrixDebug";
import { MapMatchedTracePartsSelector } from "./MapMatchedTracePartsSelector";
import React from "react";
import {
  traceEndPointLayer,
  traceStartPointLayer,
} from "../../../utils/layers";
import { getFirstAndLastPoint } from "../../../utils/geometry";
import FlowMatrixDebugTraceInfo from "./FlowMatrixDebugTraceInfo";

export const RAW_TRACE_COLOR = "#ff0000";
export const MAP_MATCHED_TRACE_COLOR = "#3da63d";
export const TRIMMED_FOR_MAP_MATCHING_TRACE_COLOR = "#0000ff";

const layersNames = {
  rawLines: "raw-traces-lines",
  rawPoints: "raw-traces-points",
  rawTraceSymbol: "raw-traces-symbol",
  trimmedLines: "trimmed-traces-lines",
  trimmedPoints: "trimmed-traces-points",
  trimmedTraceSymbol: "trimmed-traces-symbol",
  mapMatchedLines: "map-matched-traces-lines",
  mapMatchedPoints: "map-matched-traces-points",
  mapMatchedTraceSymbol: "map-matched-traces-symbol",
};

const layers = [
  {
    id: layersNames.rawLines,
    type: "line",
    filter: ["==", ["get", "traceType"], "RAW"],
    paint: {
      "line-width": 5,
      "line-color": RAW_TRACE_COLOR,
    },
    layout: {
      "line-cap": "butt",
      "line-join": "bevel",
    },
  },
  {
    id: layersNames.rawPoints,
    type: "circle",
    filter: ["==", ["get", "traceType"], "RAW"],
    paint: {
      "circle-radius": {
        base: 4,
        stops: [
          [12, 4.5],
          [14, 5],
          [16, 7],
        ],
      },
      "circle-color": RAW_TRACE_COLOR,
      "circle-stroke-width": 0.5,
      "circle-stroke-color": "#000000",
    },
  },
  {
    id: layersNames.rawTraceSymbol,
    type: "symbol",
    filter: ["==", ["get", "traceType"], "RAW"],
    layout: {
      "icon-image": "arrows",
      "icon-size": 0.75,
      "symbol-placement": "line",
      "symbol-spacing": 30,
    },
  },
  {
    id: layersNames.trimmedLines,
    type: "line",
    filter: ["==", ["get", "traceType"], "TRIMMED_FOR_MAP_MATCHING"],
    paint: {
      "line-width": 5,
      "line-color": TRIMMED_FOR_MAP_MATCHING_TRACE_COLOR,
    },
    layout: {
      "line-cap": "butt",
      "line-join": "bevel",
    },
  },
  {
    id: layersNames.trimmedPoints,
    type: "circle",
    filter: ["==", ["get", "traceType"], "TRIMMED_FOR_MAP_MATCHING"],
    paint: {
      "circle-radius": {
        base: 4,
        stops: [
          [12, 4.5],
          [14, 5],
          [16, 7],
        ],
      },
      "circle-color": TRIMMED_FOR_MAP_MATCHING_TRACE_COLOR,
      "circle-stroke-width": 0.5,
      "circle-stroke-color": "#000000",
    },
  },
  {
    id: layersNames.trimmedTraceSymbol,
    type: "symbol",
    filter: ["==", ["get", "traceType"], "TRIMMED_FOR_MAP_MATCHING"],
    layout: {
      "icon-image": "arrows",
      "icon-size": 0.75,
      "symbol-placement": "line",
      "symbol-spacing": 30,
    },
  },
  {
    id: layersNames.mapMatchedLines,
    type: "line",
    filter: ["==", ["get", "traceType"], "MAP_MATCHED"],
    paint: {
      "line-width": 5,
      "line-color": MAP_MATCHED_TRACE_COLOR,
    },
    layout: {
      "line-cap": "butt",
      "line-join": "bevel",
    },
  },
  {
    id: layersNames.mapMatchedPoints,
    type: "circle",
    filter: ["==", ["get", "traceType"], "MAP_MATCHED"],
    paint: {
      "circle-radius": {
        base: 1,
        stops: [
          [12, 4.5],
          [14, 5],
          [16, 7],
        ],
      },
      "circle-color": MAP_MATCHED_TRACE_COLOR,
      "circle-stroke-width": 0.5,
      "circle-stroke-color": "#000000",
    },
  },
  {
    id: layersNames.mapMatchedTraceSymbol,
    type: "symbol",
    filter: ["==", ["get", "traceType"], "MAP_MATCHED"],
    layout: {
      "icon-image": "arrows",
      "icon-size": 0.75,
      "symbol-placement": "line",
      "symbol-spacing": 30,
    },
  },
] as Layer[];

interface Props {
  rawTrace: Feature<Geometry, FlowMatrixDebugTraceProps>;
  mapMatchedTrace: Feature<GeometryCollection, FlowMatrixDebugTraceProps>;
  rawTrimmedForMapMatchingTrace: Feature<Geometry, FlowMatrixDebugTraceProps>;
  visibleLines: VisibleLines;
}

export const SingleTraceLayer = ({
  rawTrace,
  mapMatchedTrace,
  rawTrimmedForMapMatchingTrace,
  visibleLines,
}: Props) => {
  const map = useMap().map as Map;
  const [selectedMapMatchedPartId, setSelectedMapMatchedPartId] =
    useState<number>();

  useEffect(() => {
    setSelectedMapMatchedPartId(undefined);
  }, [mapMatchedTrace]);

  const mapMatchedTraceParts = useMemo(
    () =>
      mapMatchedTrace?.geometry?.geometries.map((geometry, index) =>
        multiLineString(geometry.coordinates as any, {
          ...mapMatchedTrace.properties,
          partId: index,
          partFailCause: mapMatchedTrace.properties?.failCause
            ? mapMatchedTrace.properties?.failCause[index]
            : undefined,
        })
      ) ?? [],
    [mapMatchedTrace]
  );

  const rawTrimmedForMapMatchingTraceParts = useMemo(
    () =>
      (
        rawTrimmedForMapMatchingTrace?.geometry as MultiLineString
      )?.coordinates.map((coords, index) => {
        return lineString(coords, {
          ...rawTrimmedForMapMatchingTrace.properties,
          partId: index,
          partFailCause: mapMatchedTrace.properties?.failCause
            ? mapMatchedTrace.properties?.failCause[index]
            : undefined,
        });
      }),
    [mapMatchedTrace, rawTrimmedForMapMatchingTrace]
  );

  const mapMatchedTracePartsToDisplay =
    selectedMapMatchedPartId !== undefined
      ? mapMatchedTraceParts?.filter(
          (it) => it.properties?.partId === selectedMapMatchedPartId
        )
      : mapMatchedTraceParts;

  const trimmedForMapMatchingTracePartsToDisplay =
    selectedMapMatchedPartId !== undefined
      ? rawTrimmedForMapMatchingTraceParts?.filter(
          (it) => it.properties?.partId === selectedMapMatchedPartId
        )
      : rawTrimmedForMapMatchingTraceParts;

  const collection = featureCollection([
    ...mapMatchedTracePartsToDisplay,
    ...trimmedForMapMatchingTracePartsToDisplay,
    rawTrace,
  ]);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      if (map.hasImage("arrows")) return;
      map.addImage("arrows", img);
    };
    img.src = arrowTriangleAlpha;

    return () => {
      map.removeImage("arrows");
    };
  }, [map]);

  useLayers("traces", layers, collection);

  useEffect(() => {
    map.setLayoutProperty(
      layersNames.rawLines + "traces",
      "visibility",
      visibleLines.raw ? "visible" : "none"
    );
  }, [visibleLines, map, collection]);

  useEffect(() => {
    map.setLayoutProperty(
      layersNames.rawPoints + "traces",
      "visibility",
      visibleLines.raw ? "visible" : "none"
    );
  }, [visibleLines, map, collection]);

  useEffect(() => {
    map.setLayoutProperty(
      layersNames.rawTraceSymbol + "traces",
      "visibility",
      visibleLines.raw ? "visible" : "none"
    );
  }, [visibleLines, map, collection]);

  useEffect(() => {
    map.setLayoutProperty(
      layersNames.trimmedLines + "traces",
      "visibility",
      visibleLines.rawTrimmedForMapMatching ? "visible" : "none"
    );
  }, [visibleLines, map, collection]);

  useEffect(() => {
    map.setLayoutProperty(
      layersNames.trimmedPoints + "traces",
      "visibility",
      visibleLines.rawTrimmedForMapMatching ? "visible" : "none"
    );
  }, [visibleLines, map, collection]);

  useEffect(() => {
    map.setLayoutProperty(
      layersNames.trimmedTraceSymbol + "traces",
      "visibility",
      visibleLines.rawTrimmedForMapMatching ? "visible" : "none"
    );
  }, [visibleLines, map, collection]);

  useEffect(() => {
    map.setLayoutProperty(
      layersNames.mapMatchedLines + "traces",
      "visibility",
      visibleLines.mapMatched ? "visible" : "none"
    );
  }, [visibleLines, map, collection]);

  useEffect(() => {
    map.setLayoutProperty(
      layersNames.mapMatchedPoints + "traces",
      "visibility",
      visibleLines.mapMatched ? "visible" : "none"
    );
  }, [visibleLines, map, collection]);

  useEffect(() => {
    map.setLayoutProperty(
      layersNames.mapMatchedTraceSymbol + "traces",
      "visibility",
      visibleLines.mapMatched ? "visible" : "none"
    );
  }, [visibleLines, map, collection]);

  const rawFirstAndLastCoordinate = getFirstAndLastPoint(rawTrace);
  const mapMatchedFirstCoordinate = getFirstAndLastPoint(
    mapMatchedTracePartsToDisplay[0]
  )?.at(0);
  const mapMatchedLastCoordinate = getFirstAndLastPoint(
    mapMatchedTracePartsToDisplay.at(-1)!!
  )?.at(1);

  useLayers(
    `sourceId-raw-start-point`,
    traceStartPointLayer,
    featureCollection([point(rawFirstAndLastCoordinate?.at(0) ?? [])])
  );

  useLayers(
    `sourceId-raw-end-point`,
    traceEndPointLayer,
    featureCollection([point(rawFirstAndLastCoordinate?.at(1) ?? [])])
  );

  useLayers(
    `sourceId-map-matched-start-point`,
    traceStartPointLayer,
    mapMatchedFirstCoordinate
      ? featureCollection([point(mapMatchedFirstCoordinate ?? [])])
      : featureCollection([])
  );

  useLayers(
    `sourceId-map-matched-end-point`,
    traceEndPointLayer,
    mapMatchedLastCoordinate
      ? featureCollection([point(mapMatchedLastCoordinate ?? [])])
      : featureCollection([])
  );

  return (
    <>
      <FlowMatrixDebugTraceInfo trace={rawTrace} />
      <MapMatchedTracePartsSelector
        selectedMapMatchedPartId={selectedMapMatchedPartId}
        setSelectedMapMatchedPartId={setSelectedMapMatchedPartId}
        mapMatchedTraceParts={rawTrimmedForMapMatchingTraceParts}
      />
    </>
  );
};
