import { Editor } from "../../Editor";
import { Feature } from "@turf/turf";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  Input,
  Label,
  Modal,
  RadioGroup,
} from "tombac";

const selectedLinkResultTypeOptions = [
  { label: "Incoming", value: "INCOMING" },
  { label: "Outgoing", value: "OUTGOING" },
];

interface Props {
  geometry: Feature[];
  selectedLinkResultType: string | undefined;
  setGeometry: (geometry: Feature[]) => void;
  setSelectedLinkResultType: (
    selectedLinkResultType: string | undefined
  ) => void;
  editorOpen: boolean;
  setEditorOpen: (editorOpen: boolean) => void;
  setLimit: (limit: number) => void;
  limit: number;
  filterByGeometry: boolean;
  setFilterByGeometry: (filterByGeometry: boolean) => void;
}

export const TracesFetchSettings = ({
  geometry,
  setGeometry,
  editorOpen,
  setEditorOpen,
  selectedLinkResultType,
  setSelectedLinkResultType,
  setLimit,
  limit,
  filterByGeometry,
  setFilterByGeometry,
}: Props) => {
  const [pendingGeometry, setPendingGeometry] = useState<Feature[]>(geometry);

  useEffect(() => {
    if (pendingGeometry) {
      setGeometry(pendingGeometry);
    }
  }, [pendingGeometry, setGeometry]);

  return (
    <Modal isOpen={editorOpen}>
      <Box
        p={2}
        display="flex"
        $justifyContent="center"
        $flexDirection="column"
        style={{ background: "#8b8888" }}
      >
        <h2>Traces fetching settings</h2>
        <Checkbox
          $marginBottom="15px"
          checked={filterByGeometry}
          onChange={(e) => setFilterByGeometry(e.target.checked)}
          label="Filter by geometry"
          variant="toggle"
        />
      </Box>
      {filterByGeometry && (
        <>
          <Label>Editor accept only features list!!!</Label>
          <Editor
            value={pendingGeometry ?? []}
            height="50vh"
            width="50vw"
            onChange={setPendingGeometry}
          />
        </>
      )}

      <Box
        p={2}
        display="flex"
        $justifyContent="space-between"
        $alignItems="center"
        $gap="40px"
        style={{ background: "#e7e4e4" }}
      >
        <FormGroup label="Result type">
          <RadioGroup
            onChange={(option) => setSelectedLinkResultType(option?.value)}
            options={selectedLinkResultTypeOptions}
            value={selectedLinkResultTypeOptions.find(
              (it) => it.value === selectedLinkResultType
            )}
            variant="horizontal"
          />
        </FormGroup>
        <FormGroup label="Limit">
          <Input
            type="number"
            value={limit}
            onChange={(e) => setLimit(parseInt(e.target.value))}
          />
        </FormGroup>
        <Button
          variant="primary"
          onClick={() => {
            setEditorOpen(false);
          }}
          disabled={limit < 1}
        >
          Show traces
        </Button>
      </Box>
    </Modal>
  );
};
