import React, { useState } from "react";
import { Box, Button, Input, Label, Modal } from "tombac";
import { pascalCaseToHumanFriendly } from "../utils/string";
import { Editor } from "./Editor";

interface Props {
  filterActive: boolean;
  index: number;
  geometry: any;
  name: string;
  editParam: (value: any, index: number) => void;
}

const GeometricFilterParam = ({
  filterActive,
  index,
  name,
  editParam,
  geometry,
}: Props) => {
  const [editorOpen, setEditorOpen] = useState(false);
  const [pendingGeometry, setPendingGeometry] = useState();

  return (
    <div style={{ marginTop: "10px" }}>
      <Label htmlFor={`${name}-input`}>{pascalCaseToHumanFriendly(name)}</Label>
      <Input
        invalid={filterActive && !geometry}
        disabled={!filterActive}
        $width={"100%"}
        type={"text"}
        id={`${name}-input`}
        placeholder={name}
        value={geometry ? JSON.stringify(geometry) : undefined}
        onClick={() => setEditorOpen(true)}
      />

      <Modal isOpen={editorOpen}>
        <Editor
          value={geometry ? geometry : {}}
          height="50vh"
          width="50vw"
          onChange={setPendingGeometry}
        />
        <Box p={2} display="flex" style={{ background: "#333" }}>
          <Button
            ml="auto"
            onClick={() => {
              setEditorOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            ml={2}
            variant="primary"
            onClick={() => {
              editParam(pendingGeometry, index);
              setEditorOpen(false);
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default GeometricFilterParam;
