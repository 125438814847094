import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Box, Button } from "tombac";
import { HOUR_IN_MS } from "../constants/constants";
import { getTracesByIds } from "../hooks/useTraces";
import throttle from "lodash/throttle";
import * as traceUtils from "../utils/traces";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { TraceSelection } from "../App";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const TraceInfo = ({
  traceId,
  setTraceSelection,
}: {
  traceId: string;
  setTraceSelection: Dispatch<SetStateAction<TraceSelection | undefined>>;
}) => {
  const trace = useMemo(() => getTracesByIds([traceId])[0], [traceId]);

  const traceStats = useMemo(() => {
    return {
      time: traceUtils.duration(trace),
      length: traceUtils.length(trace),
      startSpeed: traceUtils.startSpeed(trace),
      endSpeed: traceUtils.endSpeed(trace),
    };
  }, [trace]);

  const averageSpeed = (length?: number, time?: number) =>
    length && time ? length / (time / HOUR_IN_MS) : undefined;

  // eslint-disable-next-line
  const updater = useCallback(throttle(setTraceSelection, 200), [
    setTraceSelection,
  ]);

  if (!trace) return null;

  const times: number[] = trace.properties!.timestamps;

  return (
    <Box
      style={{ background: "#FFF", position: "fixed", bottom: 0 }}
      p={3}
      margin={2}
      zIndex={1}
    >
      Trace {trace.properties?.id}
      <Range
        min={times[0]}
        max={times[times.length - 1]}
        step={10000}
        tipFormatter={(value) =>
          new Date(value).toISOString().split(".")[0].split("T")[1]
        }
        pushable={10000 as any}
        onChange={(timeRange) =>
          updater((selection) => ({ ...selection, timeRange } as any))
        }
        defaultValue={[times[0], times[times.length - 1]]}
      />
      <div>
        <b>Time:</b>
        {` ${traceUtils.toPrettyTime(traceStats.time)}`}
      </div>
      <div>
        <b>Length:</b>
        {` ${traceStats.length.toFixed(2)} km`}
      </div>
      <div>
        <b>Average speed:</b>
        {` ${averageSpeed(traceStats.length, traceStats.time)?.toFixed(
          1
        )} km/h`}
      </div>
      <div>
        <b>Start speed:</b>
        {` ${traceStats.startSpeed.toFixed(1)} km/h`}
      </div>
      <div>
        <b>End speed:</b>
        {` ${traceStats.endSpeed.toFixed(1)} km/h`}
      </div>
      <Button
        mt={1}
        $width={"100%"}
        onClick={(e) => traceUtils.saveTraceToFile(trace)}
      >
        Save trace as geoJSON
      </Button>
    </Box>
  );
};

export default TraceInfo;
