import { featureCollection } from "@turf/turf";
import { useAuth } from "legoland-sdk";
import React, { useState } from "react";
import { Button, FormGroup, Input } from "tombac";
import { Section } from "./Section";

const fetchGeometry = async (id: string, apiKey: string) => {
  const analysis = await fetch(
    `https://api.tomtom.com/origindestination/rest/analysis/${id}?getResult=false&key=${apiKey}`,
    {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((it) => it.json());

  let geometry = await fetch(analysis.regionsLink + "&key=" + apiKey, {
    credentials: "include",
  }).then((it) => it.json());

  return featureCollection(geometry);
};

const getInfoFromURL = (url: string) => {
  const urlRegex = /(?:view|share)\/([0-9]+)/.exec(url);

  if (urlRegex === null) {
    return;
  }

  return { id: urlRegex[1] };
};

export function NukusLoader({ onGeometry }: { onGeometry: (g: any) => void }) {
  const { me } = useAuth();
  const apiKey = me?.products.find((it) => it.product === "od")?.apiKey;
  const [report, setReport] = useState<any | undefined>();

  return (
    <Section title="Import from O/D">
      <FormGroup label="Report URL">
        <Input
          placeholder="Report URL"
          onChange={(e) => {
            const report = getInfoFromURL(e.target.value);
            setReport(report);
          }}
        ></Input>
      </FormGroup>
      <Button
        mt={1}
        onClick={async () => {
          if (report && apiKey) {
            const geometry = await fetchGeometry(report.id, apiKey);
            onGeometry(geometry);
          }
        }}
      >
        Fetch geometry
      </Button>
    </Section>
  );
}
