import { FeatureCollection } from "@turf/turf";
import React from "react";
import styled from "styled-components";
import { Select } from "tombac";
import { FlowMatrixDebugTraceProps } from "../../api/model/flowMatrixDebug";

const SearchContainer = styled.div`
  right: 60px;
  position: fixed;
`;

interface Props {
  traces: FeatureCollection<any, FlowMatrixDebugTraceProps>;
  setSelectedTraceId: (id?: string) => void;
}

export const TraceSearch = ({ traces, setSelectedTraceId }: Props) => {
  const options = traces.features.map((it) => {
    return { value: it.properties?.id!!, label: it.properties?.id!! };
  });

  const handleOnSelect = (e: any) => {
    if (e) {
      setSelectedTraceId(e.label);
    }
  };

  return (
    <SearchContainer>
      <Select
        $width="250px"
        isClearable
        isSearchable
        placeholder="Search trace by id..."
        options={options}
        onChange={(e: any) => handleOnSelect(e)}
      />
    </SearchContainer>
  );
};
