import React, { useEffect, useState } from "react";
import { Feature } from "@turf/turf";
import { Box, Button } from "tombac";
import { ScrollableList } from "../TracesList";

interface Props {
  queriedTraces: Feature[];
  setHoveredTrace: (trace?: Feature) => void;
  setChosenTrace: (trace?: Feature) => void;
}

const TracesList: React.FC<Props> = ({
  queriedTraces,
  setHoveredTrace,
  setChosenTrace,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(true);
    return () => {
      setIsOpen(false);
    };
  }, [queriedTraces]);

  return (
    <>
      {isOpen && (
        <Box maxHeight={"91vh"} $width={"160px"} zIndex={15} color={"white"}>
          <ScrollableList>
            {queriedTraces.map((trace: Feature, i) => (
              <li
                key={i}
                onClick={() => setChosenTrace(trace)}
                onMouseEnter={() => setHoveredTrace(trace)}
                onMouseLeave={() => setHoveredTrace(undefined)}
              >
                <Button
                  marginBottom={"1px"}
                  $width={"100%"}
                  size="s"
                  style={{ fontSize: "11px" }}
                >
                  {trace?.properties?.id}
                </Button>
              </li>
            ))}
          </ScrollableList>
        </Box>
      )}
    </>
  );
};

export default TracesList;
