import * as wellknown from "wellknown";
import * as turf from "@turf/turf";
import { FeatureCollection, Geometry } from "@turf/turf";
import {
  FlowMatrixDebugTrace,
  FlowMatrixDebugTraceProps,
  FlowMatrixDebugTraceType,
} from "../api/model/flowMatrixDebug";

export const flowMatrixDebugTracesToFeatureCollection = (
  traces: FlowMatrixDebugTrace[]
): FeatureCollection<any, FlowMatrixDebugTraceProps>[] => {
  const rawFeatures: turf.Feature[] = [];
  const rawTrimmedForMapMatchingFeatures: turf.Feature[] = [];
  const mapMatchedFeatures: turf.Feature[] = [];

  traces.forEach((it) => {
    const properties: FlowMatrixDebugTraceProps = {
      id: it.id,
      avgSpeedMs: it.avgSpeedMs,
      lengthInMeters: it.lengthInMeters,
      failCause: parseStringToArray(it.failCause as any),
      org: it.org,
    };

    rawFeatures.push(
      wktToFeature(it.rawGeometry, {
        ...properties,
        traceType: FlowMatrixDebugTraceType.RAW,
      })
    );

    if (it.rawTrimmedForMapMatchingGeometry) {
      rawTrimmedForMapMatchingFeatures.push(
        wktToFeature(it.rawTrimmedForMapMatchingGeometry, {
          ...properties,
          traceType: FlowMatrixDebugTraceType.TRIMMED_FOR_MAP_MATCHING,
        })
      );
    }

    if (it.mapMatchedGeometry) {
      mapMatchedFeatures.push(
        wktToFeature(it.mapMatchedGeometry, {
          ...properties,
          traceType: FlowMatrixDebugTraceType.MAP_MATCHED,
        })
      );
    }
  });

  const rawFeatureCollection = turf.featureCollection(rawFeatures);
  const rawTrimmedForMapMatchingFeatureCollection = turf.featureCollection(
    rawTrimmedForMapMatchingFeatures
  );
  const mapMatchedFeatureCollection =
    turf.featureCollection(mapMatchedFeatures);

  if (rawFeatureCollection === null) {
    throw new Error("No features found");
  }

  return [
    rawFeatureCollection,
    rawTrimmedForMapMatchingFeatureCollection,
    mapMatchedFeatureCollection,
  ] as FeatureCollection<Geometry, FlowMatrixDebugTraceProps>[];
};

export const wktToFeature = (wkt: string, properties?: turf.Properties) => {
  // Replace empty multiline string with line string to avoid parsing error
  const preparedWkt = wkt.replaceAll(
    "MULTILINESTRING EMPTY",
    "LINESTRING (0 0, 0 0.0001,)"
  );
  let geojson = wellknown.parse(preparedWkt);
  return turf.feature(geojson as turf.Geometry, properties);
};

export const getFirstAndLastPoint = (trace?: turf.Feature) => {
  if (!trace) {
    return undefined;
  }

  let firstCoordinate;
  let lastCoordinate;

  if (trace.geometry?.type === "LineString") {
    firstCoordinate = trace.geometry.coordinates[0];
    lastCoordinate = trace.geometry.coordinates.at(-1);
  } else {
    firstCoordinate = (trace.geometry as any).coordinates[0][0];
    lastCoordinate = (trace.geometry as any).coordinates.at(-1)[
      (trace.geometry as any).coordinates.at(-1).length - 1
    ];
  }

  return [firstCoordinate, lastCoordinate];
};

const parseStringToArray = (str: string) => {
  if (str === "null" || !str) {
    return undefined;
  }
  const trimmedInput = str.slice(1, -1).replaceAll(" ", "");
  const rawArray = trimmedInput.split(",");

  const parsedArray = rawArray.map((element) => {
    if (element === "") {
      return undefined;
    } else {
      return element;
    }
  });

  return parsedArray;
};
