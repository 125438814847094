import React, { useEffect, useMemo, useState } from "react";
import { useMap } from "vector-maps/dist/MapContext";
import { Layer, Map } from "mapbox-gl";
import { FeatureCollection } from "@turf/turf";
import { useLayers } from "../../hooks/useLayers";

interface Props {
  traces: FeatureCollection;
  showTraces: boolean;
  showPoints: boolean;
  color: string;
  layerId?: number;
}

export const TracesLayer: React.FC<Props> = ({
  traces,
  showTraces,
  showPoints,
  color,
  layerId,
}) => {
  const [id] = useState(layerId ?? (Math.random() * 1000) | 0);
  const map = useMap().map as Map;

  const layers = useMemo<Layer[]>(
    () => [
      {
        id: "traces-lines",
        type: "line",
        paint: {
          "line-width": 1,
          "line-color": color,
          "line-opacity": 0.2,
        },
        layout: {
          "line-cap": "butt",
          "line-join": "bevel",
        },
      },
      {
        id: "traces-points",
        type: "circle",
        paint: {
          "circle-radius": {
            base: 1.5,
            stops: [
              [12, 1],
              [22, 15],
            ],
          },
          "circle-color": color,
          "circle-opacity": 0.2,
        },
      },
    ],
    [color]
  );

  useLayers(String(id), layers, traces, "Service road icon1");

  useEffect(() => {
    map.setLayoutProperty(
      "traces-lines" + id,
      "visibility",
      showTraces ? "visible" : "none"
    );
  }, [showTraces, map, id]);

  useEffect(() => {
    map.setLayoutProperty(
      "traces-points" + id,
      "visibility",
      showPoints ? "visible" : "none"
    );
  }, [showPoints, map, id]);

  return null;
};
