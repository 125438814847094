import { FeatureCollection, Geometry } from "@turf/turf";
import React from "react";
import { GeometryLayer } from "../../GeometryLayer";

interface Props {
  buffer: FeatureCollection<Geometry>;
}

export const BufferLayer = ({ buffer }: Props) => {
  return (
    <>
      <GeometryLayer geometry={buffer} />
    </>
  );
};
