import { Feature } from "@turf/turf";
import React from "react";
import { useLayers } from "../../../hooks/useLayers";
import * as turf from "@turf/turf";
import {
  chosenTraceLayers,
  hoveredTraceLayers,
  traceEndPointLayer,
  traceStartPointLayer,
} from "../../../utils/layers";
import NukusTraceInfo from "./FlowMatrixDebugTraceInfo";

interface Props {
  sourceId: string;
  trace: Feature<any, any>;
  hovered?: boolean;
  color?: string;
}

const FlowMatrixDebugColoredTrace: React.FC<Props> = ({
  sourceId,
  trace,
  hovered,
  color = "#FF0000",
}) => {
  trace.properties = {
    ...trace?.properties,
    color,
  };

  useLayers(
    sourceId,
    hovered ? hoveredTraceLayers : chosenTraceLayers,
    turf.featureCollection([trace])
  );

  let firstCoordinate;
  let lastCoordinate;

  if (trace.geometry?.type === "LineString") {
    firstCoordinate = trace.geometry.coordinates[0];
    lastCoordinate = trace.geometry.coordinates.at(-1);
  } else if (trace.geometry?.type === "MultiLineString") {
    firstCoordinate = (trace.geometry as any).coordinates[0][0];
    lastCoordinate = (trace.geometry as any).coordinates.at(-1)[
      (trace.geometry as any).coordinates.at(-1).length - 1
    ];
  } else if (trace.geometry?.type === "GeometryCollection") {
    firstCoordinate = (trace.geometry as any).geometries[0].coordinates[0][0];
    lastCoordinate = (trace.geometry as any).geometries
      .at(-1)
      .coordinates.at(-1)
      .at(-1);
  }

  useLayers(
    `${sourceId}-start-point`,
    traceStartPointLayer,
    turf.featureCollection([turf.point(firstCoordinate)])
  );

  useLayers(
    `${sourceId}-end-point`,
    traceEndPointLayer,
    turf.featureCollection([turf.point(lastCoordinate)])
  );

  return <NukusTraceInfo trace={trace} />;
};

export default FlowMatrixDebugColoredTrace;
