import React, { useEffect, useMemo } from "react";
import { bbox, Geometry } from "@turf/turf";
import niceColors from "nice-color-palettes";
import styled from "styled-components";
import { RegionSection } from "./RegionSection";
import { Box, Button, DatePicker, FormGroup, Input, Label } from "tombac";
import { useTraces } from "../hooks/useTraces";
import { GeometryLayer } from "./GeometryLayer";
import { TracesLayer } from "./TracesLayer";
import { HuePicker } from "react-color";
import { Section } from "./Section";
import OrganisationPicker from "./OrganisationPicker";
import { Organization } from "../constants/organisation";
import ManageFilters from "./ManageFilters";
import { Filter } from "../commonTypes";
import ColoredTrace from "./ColoredTrace";
import { DeleteIcon } from "tombac-icons";
import { FargoLoader } from "./FargoLoader";
import { NukusLoader } from "./NukusLoader";
import { useMap } from "vector-maps/dist/MapContext";
import { TraceSelection } from "../App";
export interface TraceGroup {
  id: number;
  name: string;
  color: string;
  geometry: Geometry;
  date: Date;
  organisations: Set<Organization>;
  filters: Filter[];
  limit: number | string;
  hidden: boolean;
}

export const makeTraceGroup = (filters: Filter[]): TraceGroup => ({
  id: (Math.random() * 10000) | 0,
  name: "Group",
  color: niceColors[13][Math.floor(Math.random() * 5)],
  geometry: {
    coordinates: [
      [
        [19.45241595, 51.759298813],
        [19.45241595, 51.758048969],
        [19.462539685, 51.759161845],
        [19.462124778, 51.760343176],
        [19.45241595, 51.759298813],
      ],
    ],
    type: "Polygon",
  },
  date: new Date(),
  organisations: new Set(),
  filters: filters,
  limit: 1000,
  hidden: false,
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

export function TraceGroupSection({
  group,
  onChange,
  onDelete,
  traceSelection,
}: {
  group: TraceGroup;
  onChange: (g: TraceGroup) => void;
  onDelete: () => void;
  traceSelection?: TraceSelection;
}) {
  const { map } = useMap();
  const setGroup = (g: Partial<TraceGroup>) => onChange({ ...group, ...g });

  const centerOnRegions = (features: any) => {
    const regionsBbox = bbox(features);
    map.fitBounds(regionsBbox as any, { padding: 100, animate: false });
  };

  const traces = useTraces(
    group.id,
    group.geometry,
    group.date,
    group.organisations,
    group.filters,
    Number(group.limit)
  );

  const selectedTrace = useMemo(() => {
    if (traceSelection === undefined || traces === undefined) {
      return;
    }
    let trace = traces.collection?.features.find(
      (it) => it?.properties?.id === traceSelection.id
    );

    if (!trace) return;

    // Trim trace if we selected some time range
    if (traceSelection.timeRange !== undefined) {
      const range = traceSelection.timeRange;
      const timings: number[] = trace.properties!.timestamps;
      const from = timings.findIndex((it) => it > range[0]);
      const to = timings.findIndex((it) => it > range[1]);

      trace = {
        ...trace,
        properties: {
          ...trace.properties,
          timestamps: timings.slice(from, to),
        } as any,
        geometry: {
          ...trace.geometry,
          coordinates: trace.geometry!.coordinates.slice(from, to),
        } as any,
      };
    }

    return trace;
  }, [traceSelection, traces]);

  useEffect(() => {
    if (!group.filters) return;
    try {
      const filters = [...group.filters];
      filters.forEach((filter) => {
        filter.params.forEach((param) => {
          if (param.type === "GEOMETRY" && param.value === null) {
            param.value = group.geometry;
          }
        });
      });
      setGroup({ filters: filters });
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const zoom = () => {
    const regionsBbox = bbox(group.geometry);
    map.fitBounds(regionsBbox as any, { padding: 100, animate: false });
  };

  return (
    <>
      <Section
        title={
          <>
            <div
              style={{
                background: group.color,
                width: "10px",
                height: "10px",
                marginRight: "5px",
              }}
            />
            {group.name}
          </>
        }
        actions={
          <Box onClick={(e) => e.stopPropagation()} ml="auto">
            <Button ml={1} size="s" onClick={zoom}>
              Zoom
            </Button>
            <Button
              ml={1}
              size="s"
              onClick={() => {
                setGroup({ hidden: !group.hidden });
              }}
            >
              {group.hidden ? "Show" : "Hide"}
            </Button>
            <Button
              ml={1}
              prepend={<DeleteIcon width="14px" />}
              onClick={onDelete}
              size="s"
              variant="danger"
            />
          </Box>
        }
      >
        <Container>
          <Box display="flex" alignItems="center">
            <FormGroup label="Name">
              <Input
                value={group.name}
                onChange={(e) => setGroup({ name: e.target.value })}
              ></Input>
            </FormGroup>
            <FormGroup label="Day" ml="auto">
              <DatePicker
                value={group.date}
                onChange={(date: any) => setGroup({ date })}
              />
            </FormGroup>
          </Box>

          <Box display="flex" mt={3}>
            <FormGroup label="Limit">
              <Input
                placeholder="Limit"
                value={group.limit}
                onChange={(e) => setGroup({ limit: e.target.value })}
              />
            </FormGroup>

            <FormGroup label="Color" ml={2}>
              <HuePicker
                color={group.color}
                onChange={(e) => setGroup({ color: e.hex })}
              />
            </FormGroup>
          </Box>

          <Box display="flex" alignItems="center" mt={3}>
            <RegionSection
              geometry={group.geometry}
              setGeometry={(geometry: any) => {
                centerOnRegions(geometry);
                setGroup({ geometry });
              }}
            />

            <OrganisationPicker
              organisations={group.organisations}
              setOrganisations={(organisations) => setGroup({ organisations })}
            />

            <ManageFilters
              filters={group.filters}
              setFilters={(filters) => setGroup({ filters })}
            />
          </Box>

          <FargoLoader
            onGeometry={(geometry) => {
              centerOnRegions(geometry);
              setGroup({ geometry });
            }}
          />
          <NukusLoader
            onGeometry={(geometry) => {
              centerOnRegions(geometry);
              setGroup({ geometry });
            }}
          />

          <Label mt={4}>Traces</Label>
          <Box display="flex" alignItems="center" mt={1}>
            <Button
              variant="accent"
              busy={traces.isLoading}
              onClick={() => {
                traces.load();
              }}
              mr={2}
              style={{ flexGrow: 1 }}
            >
              Load
            </Button>
            <Button onClick={traces.clear}>Clear</Button>
          </Box>
        </Container>
      </Section>

      {!group.hidden && (
        <>
          <GeometryLayer geometry={group.geometry} />
          {traces.collection && (
            <TracesLayer
              showTraces
              showPoints
              color={group.color}
              traces={traces.collection}
              layerId={group.id}
            />
          )}
          {selectedTrace && (
            <ColoredTrace
              sourceId={"selected-trace" + group.id}
              trace={selectedTrace}
            />
          )}
        </>
      )}
    </>
  );
}
