import React, { useRef, useState } from "react";
import { Alert, Box, Button } from "tombac";
import { UploadIcon } from "tombac-icons";
import { FeatureCollection, Geometry } from "@turf/turf";
import { readCsvData } from "../../../../utils/csvReader";
import { FlowMatrixDebugTraceProps } from "../../../../api/model/flowMatrixDebug";
import { flowMatrixDebugTracesToFeatureCollection } from "../../../../utils/geometry";

interface Props {
  setRawTraces: (
    traces: FeatureCollection<Geometry, FlowMatrixDebugTraceProps>
  ) => void;
  setRawTracesTrimmedForMapMatching: (
    traces: FeatureCollection<Geometry, FlowMatrixDebugTraceProps>
  ) => void;
  setMapMatchedTraces: (
    traces: FeatureCollection<Geometry, FlowMatrixDebugTraceProps>
  ) => void;
  setIsOpen: (isOpen: boolean) => void;
}

export const UploadCsv = ({
  setIsOpen,
  setRawTraces,
  setMapMatchedTraces,
  setRawTracesTrimmedForMapMatching,
}: Props) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fileInput = useRef<HTMLInputElement>(null);

  async function onChange() {
    if (!fileInput.current) return;
    if (fileInput.current.files !== null) {
      setIsLoading(true);
      const reader = new FileReader();
      const file = fileInput.current.files[0];
      reader.readAsText(file);
      reader.onload = async (r) => {
        try {
          const debugTraces = await readCsvData(r.target?.result as string);
          const featuresCollections =
            flowMatrixDebugTracesToFeatureCollection(debugTraces);
          setRawTraces(featuresCollections[0]);
          setRawTracesTrimmedForMapMatching(featuresCollections[1]);
          setMapMatchedTraces(featuresCollections[2]);
          setIsError(false);
          setIsOpen(false);
        } catch (error) {
          console.error(error);
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      };
    }
  }

  return (
    <Box $width="100%">
      <input
        type="file"
        id="csvFileInput"
        ref={fileInput}
        style={{ display: "none" }}
        accept=".csv"
        onChange={onChange}
      />
      <Button
        $margin="10px"
        $borderRadius="10px"
        $padding="30px"
        busy={isLoading}
        variant="ghost"
        onClick={() => fileInput?.current?.click?.()}
        prepend={<UploadIcon />}
      >
        Upload CSV
      </Button>
      {isError && (
        <Alert $margin="10px" $width="85%" title="Danger!" variant="danger">
          Wrong file structure
        </Alert>
      )}
    </Box>
  );
};
