import { Layer } from "mapbox-gl";
import { useState } from "react";
import { useLayers } from "../hooks/useLayers";

const layers: Layer[] = [
  {
    id: "geometry-line",
    type: "line",
    paint: {
      "line-width": 2,
      "line-color": "rgba(97,173,224,0.5)",
    },
    layout: {
      "line-cap": "butt",
      "line-join": "bevel",
    },
  },
  {
    id: "geometry-fill",
    type: "fill",
    paint: {
      "fill-color": "rgba(97,173,224,0.1)",
    },
  },
  {
    id: "geometry-text",
    type: "symbol",
    layout: {
      "symbol-placement": "point",
      "text-field": "{name}",
      "text-font": ["FrutigerHelveticaMYingHei-Regular"],
      "text-offset": [0, 0.1],
      "text-size": 20,
    },
    paint: {
      "text-opacity": 1,
      "text-opacity-transition": {
        duration: 1,
        delay: 0,
      },
      "icon-opacity-transition": {
        duration: 0,
      },
      "text-halo-color": "rgba(255,255,255,0.9)",
      "text-halo-width": 1.5,
      "text-color": "#100e42",
    },
  },
];

export function GeometryLayer(props: { geometry: any }) {
  const [id] = useState((Math.random() * 1000) | 0);
  useLayers("geometry-layer" + id, layers, props.geometry, undefined, false);

  return null;
}
