import * as turf from "@turf/turf";
import { Feature, FeatureCollection, Geometry } from "@turf/turf";
import chroma from "chroma-js";
import { Segment } from "../commonTypes";
import { HOUR_IN_MS, SECOND_IN_MS } from "../constants/constants";
import { FlowMatrixDebugTraceProps } from "../api/model/flowMatrixDebug";

const first = (a: number[]) => a[0];
const last = (a: number[]) => a[a.length - 1];

const segmentSpeed = (feature: any, i: number) => {
  if (i > feature.geometry.coordinates.length - 2) {
    return 99999;
  }
  const coordStart = feature.geometry.coordinates[i];
  const coordEnd = feature.geometry.coordinates[i + 1];

  const timeStart = feature.properties.timestamps[i];
  const timeEnd = feature.properties.timestamps[i + 1];

  const posDiff = turf.distance(coordStart, coordEnd, {
    units: "kilometers",
  });
  const timeDiff = (timeEnd - timeStart) / 3600000;

  return posDiff / timeDiff;
};

export const toPrettyTime = (ms: number) => {
  const date = new Date(ms);
  const pad = (s: number) => (s > 9 ? s : "0" + s);
  return pad(date.getUTCHours()) + ":" + pad(date.getUTCMinutes());
};

export const startTime = (trace: any) =>
  toPrettyTime(trace.properties.timestamps[0]);

export const endTime = (trace: any) =>
  toPrettyTime(
    trace.properties.timestamps[trace.properties.timestamps.length - 1]
  );

export const longerThan3Minutes = (feature: any) => {
  const start = first(feature.properties.timestamps);
  const end = last(feature.properties.timestamps);

  const diff = end - start;
  const threeMinutes = 3 * 60 * 1000 + 2000;
  return diff > threeMinutes;
};

export const startSpeed = (f: any) => {
  return Math.min(...[1, 2, 3].map((s) => segmentSpeed(f, s)));
};

export const length = (feature: any) => {
  return turf.length(feature as turf.AllGeoJSON, {
    units: "kilometers",
  });
};

export const endSpeed = (feature: any) => {
  return Math.min(
    ...[2, 3, 4].map((s) =>
      segmentSpeed(feature, feature.properties.timestamps.length - s)
    )
  );
};

export const toKmh = (speedMs: number) => {
  return speedMs * 3.6;
};

export const duration = (feature: any) => {
  return (
    feature.properties.timestamps[feature.properties.timestamps.length - 1] -
    feature.properties.timestamps[0]
  );
};

export const platinum = (feature: any) => {
  return startSpeed(feature) < 15 && endSpeed(feature) < 15;
};

export const isNearMidnight = (feature: any) => {
  const timeNearMidnight = (date: Date) => {
    const hour = date.getUTCHours();
    const minute = date.getUTCMinutes();

    return (hour === 23 && minute > 58) || (hour === 0 && minute < 2);
  };
  const start = new Date(feature.properties.timestamps[0]);
  const end = new Date(
    feature.properties.timestamps[feature.properties.timestamps.length - 1]
  );

  return timeNearMidnight(start) || timeNearMidnight(end);
};

export const compareTracesByStartTime = (a: Feature, b: Feature) => {
  const startTimeA = startTime(a);
  const startTimeB = startTime(b);
  if (startTimeA && startTimeB) {
    if (startTimeA > startTimeB) return 1;
    if (startTimeA < startTimeB) return -1;
  }
  return 0;
};

export const getColoredTrace = (trace?: Feature) => {
  if (!trace) {
    return { lines: undefined, gaps: undefined };
  }

  const timings: number[] = trace?.properties?.timestamps;
  const coords = (trace?.geometry as Geometry).coordinates as number[][];
  const segments: Segment[] = [];
  const scale = chroma.scale(["red", "yellow", "green"]);

  for (let i = 1; i < coords.length; i++) {
    const prevPos = coords[i - 1];
    const prevTime = timings[i - 1];
    const pos = coords[i];
    const time = timings[i];
    const distanceInMetres = turf.distance(prevPos, pos, {
      units: "metres",
    });
    const distanceInUnit = turf.distance(prevPos, pos, {
      units: "kilometers",
    });
    const hours = (time - prevTime) / HOUR_IN_MS;
    const speed = distanceInUnit / hours;

    segments.push({
      durationInSeconds: (time - prevTime) / SECOND_IN_MS,
      time,
      speed,
      start: prevPos,
      end: pos,
      color: "",
      distance: distanceInMetres,
    });
  }

  const maxSpeed = Math.max(...segments.map((x: Segment) => x.speed));
  const getFeature = (segment: Segment) =>
    turf.lineString([segment.start, segment.end], {
      time: segment.time,
      color:
        segment.distance < 500
          ? scale(segment.speed / (maxSpeed * 1)).css()
          : "rgba(148, 148, 148, 0.5)",
      speed: segment.speed,
      distance: segment.distance,
      durationInSeconds: segment.durationInSeconds,
    });

  const lines: Feature[] = [];
  const gaps: Feature[] = [];

  segments.forEach((segment) =>
    segment.distance < 500
      ? lines.push(getFeature(segment))
      : gaps.push(getFeature(segment))
  );

  return {
    lines: turf.featureCollection(lines),
    gaps: turf.featureCollection(gaps),
  };
};

export const tracesCountByOrg = (
  traces: FeatureCollection<any, FlowMatrixDebugTraceProps>
) => {
  let countByOrg = new Map<string, number>();

  traces.features.forEach((feature) => {
    const org = feature.properties?.org!!;
    if (countByOrg.has(org)) {
      countByOrg.set(org, countByOrg.get(org)!! + 1);
    } else {
      countByOrg.set(org, 1);
    }
  });

  return new Map([...countByOrg.entries()].sort((a, b) => b[1] - a[1]));
};

export const saveTraceToFile = (trace: any) => {
  const dateFilename = () => {
    const date = new Date();
    return `${date.getUTCFullYear()}-${date.getUTCMonth()}-${
      date.getUTCDate() + 1
    }__${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}.geojson`;
  };

  const fileData = JSON.stringify(trace);
  const blob = new Blob([fileData], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = dateFilename();
  link.href = url;
  link.click();
};
