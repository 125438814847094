import { Feature } from "@turf/turf";
import React, { useEffect, useState } from "react";
import * as turf from "@turf/turf";
import {
  FINAL_TRACE_COLOR,
  MAP_MATCHED_TRACE_COLOR,
  RAW_TRACE_COLOR,
  TracesLayer,
} from "./TracesLayer";
import { TraceSelector } from "./TraceSelector";
import styled from "styled-components";
import { TracesConfig } from "./TracesConfig";
import {
  TraceProps,
  TraceType,
} from "../../../hooks/useSelectedLinkDebugTraces";
import { Checkbox, HFlex } from "tombac";
import {
  FIRST_TRACE_REPORT_COLOR,
  LAST_TRACE_REPORT_COLOR,
} from "../../../utils/layers";
import {
  LegendColorContainer,
  LegendLineColorContainer,
} from "../legend.style";
import { useLink } from "../../../hooks/useLink";
import { useMap } from "vector-maps/dist/MapContext";
import { BufferLayer } from "./BufferLayer";

const ControlsContainer = styled.div`
  position: absolute;
  left: 0;
  margin: 10px;
  display: flex;
  flex-direction: column;
  z-index: 15;
  font-size: 14px;
  width: 300px;
  max-height: 90vh;
  background: white;
  padding: 10px;
  gap: 10px;
  border-radius: 10px;
`;

const LegendContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 15;
  font-size: 12px;
  width: 170px;
  max-height: 90vh;
  background: white;
  padding: 10px;
  border-radius: 10px;
`;

interface Props {
  featuresByTraceProps: Map<TraceProps, Feature<any>[]>;
  setEditorOpen: (modalOpen: boolean) => void;
}

export const SelectedLinkDebugVisualization = ({
  featuresByTraceProps,
  setEditorOpen,
}: Props) => {
  const { map } = useMap();
  const [traceTypesToShow, setTraceTypesToShow] = useState<TraceType[]>([
    TraceType.RAW,
    TraceType.FINAL,
    TraceType.MAP_MATCHED,
  ]);

  const { link, buffer } = useLink();

  const [tracesToShow, setTracesToShow] = useState<Feature[]>(
    featuresByTraceProps.entries().next().value[1]
  );

  const [selectedTraceProps, setSelectedTraceProps] = useState<TraceProps>(
    featuresByTraceProps.entries().next().value[0]
  );

  useEffect(() => {
    const filteredTraces = featuresByTraceProps
      .get(selectedTraceProps)
      ?.filter((trace) =>
        traceTypesToShow.some((it) => it === trace?.properties?.type)
      );
    setTracesToShow(filteredTraces || []);
  }, [featuresByTraceProps, selectedTraceProps, traceTypesToShow]);

  const handleCheckboxChange = (traceType: TraceType) => {
    const newTraceTypesToShow = traceTypesToShow.includes(traceType)
      ? traceTypesToShow.filter((it) => it !== traceType)
      : [...traceTypesToShow, traceType];
    if (newTraceTypesToShow.length !== 0) {
      setTraceTypesToShow(newTraceTypesToShow);
    }
  };

  useEffect(() => {
    if (link) {
      const regionsBbox = turf.bbox(link);
      map.fitBounds(regionsBbox as any, { padding: 450, animate: true });
    }
  }, [map, link]);

  return (
    <>
      {link && (
        <TracesLayer
          traces={turf.featureCollection(tracesToShow)}
          link={link}
        />
      )}
      {buffer && <BufferLayer buffer={buffer} />}

      <ControlsContainer>
        <TracesConfig
          tracesNumber={featuresByTraceProps.size}
          setEditorOpen={setEditorOpen}
        />
        <TraceSelector
          selectedTraceProps={selectedTraceProps}
          setSelectedTraceProps={setSelectedTraceProps}
          tracesProps={Array.from(featuresByTraceProps.keys())}
        />
      </ControlsContainer>
      <LegendContainer>
        <HFlex alignItems="center">
          <Checkbox
            checked={traceTypesToShow.includes(TraceType.RAW)}
            onChange={() => handleCheckboxChange(TraceType.RAW)}
          />
          <LegendLineColorContainer
            $color={RAW_TRACE_COLOR}
          ></LegendLineColorContainer>{" "}
          Raw trace
        </HFlex>
        <HFlex alignItems="center">
          <Checkbox
            checked={traceTypesToShow.includes(TraceType.MAP_MATCHED)}
            onChange={() => handleCheckboxChange(TraceType.MAP_MATCHED)}
          />
          <LegendLineColorContainer
            $color={MAP_MATCHED_TRACE_COLOR}
          ></LegendLineColorContainer>{" "}
          Map matched trace
        </HFlex>
        <HFlex alignItems="center">
          <Checkbox
            checked={traceTypesToShow.includes(TraceType.FINAL)}
            onChange={() => handleCheckboxChange(TraceType.FINAL)}
          />
          <LegendLineColorContainer
            $color={FINAL_TRACE_COLOR}
          ></LegendLineColorContainer>
          Final trace
        </HFlex>
        <HFlex alignItems="center">
          <LegendColorContainer
            $color={FIRST_TRACE_REPORT_COLOR}
          ></LegendColorContainer>{" "}
          First trace report
        </HFlex>
        <HFlex alignItems="center">
          <LegendColorContainer
            $color={LAST_TRACE_REPORT_COLOR}
          ></LegendColorContainer>{" "}
          Last trace report
        </HFlex>
      </LegendContainer>
    </>
  );
};
