import React, { useEffect, useMemo, useState } from "react";
import {
  FeatureCollection,
  Geometry,
  GeometryCollection,
  Polygon,
  bbox,
} from "@turf/turf";
import { TracesLayer } from "../TracesLayer";
import { FlowMatrixDebugTracesSelector } from "./FlowMatrixDebugTracesSelector";
import FlowMatrixDebugColoredTrace from "./FlowMatrixDebugColoredTrace";
import { useMap } from "vector-maps/dist/MapContext";
import styled from "styled-components";
import { Checkbox, HFlex } from "tombac";
import { TraceSearch } from "../TraceSearch";
import { RegionsLayer } from "../RegionsLayer";
import {
  FIRST_TRACE_REPORT_COLOR,
  LAST_TRACE_REPORT_COLOR,
} from "../../../utils/layers";
import {
  FlowMatrixDebugTraceProps,
  FlowMatrixDebugTraceType,
  TracesOrigin as TraceOrigin,
  ViewMode,
  VisibleLines,
} from "../../../api/model/flowMatrixDebug";
import { FlowMatrixDebugControls } from "./FlowMatrixDebugControls";
import { tracesCountByOrg } from "../../../utils/traces";
import {
  MAP_MATCHED_TRACE_COLOR,
  RAW_TRACE_COLOR,
  SingleTraceLayer,
  TRIMMED_FOR_MAP_MATCHING_TRACE_COLOR,
} from "./SingleTraceLayer";
import {
  LegendColorContainer,
  LegendLineColorContainer,
} from "../legend.style";

const LegendContainer = styled.div<{ $isTraceActive: boolean }>`
  position: fixed;
  bottom: ${(props) => (props.$isTraceActive ? "205px" : "0")};
  right: 0;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 15;
  font-size: 12px;
  width: 165px;
  background: white;
  padding: 10px;
  border-radius: 10px;
`;

interface Props {
  rawTraces: FeatureCollection<Geometry, FlowMatrixDebugTraceProps>;
  mapMatchedTraces: FeatureCollection<
    GeometryCollection,
    FlowMatrixDebugTraceProps
  >;
  rawTracesTrimmedForMapMatching: FeatureCollection<
    Geometry,
    FlowMatrixDebugTraceProps
  >;
  regions?: FeatureCollection<Polygon, { name: string }>;
  tracesOrigin: TraceOrigin;
  setTracesOrigin: (failedTraces: TraceOrigin) => void;
}

export const FlowMatrixDebug = ({
  rawTraces,
  mapMatchedTraces,
  rawTracesTrimmedForMapMatching,
  regions,
  setTracesOrigin,
  tracesOrigin,
}: Props) => {
  const { map } = useMap();
  const [selectedTracesType, setSelectedTracesType] =
    useState<FlowMatrixDebugTraceType>(FlowMatrixDebugTraceType.RAW);
  const traces = useMemo(() => {
    if (selectedTracesType === FlowMatrixDebugTraceType.RAW) return rawTraces;
    else return mapMatchedTraces;
  }, [selectedTracesType, rawTraces, mapMatchedTraces]);

  const [selectedTraceId, setSelectedTraceId] = useState<string>();
  const [filteredTraces, setFilteredTraces] = useState(traces);
  const [showRegions, setShowRegions] = useState(true);
  const featureByOrg = tracesCountByOrg(traces);
  const [selectedOrgs, setSelectedOrgs] = useState<Set<string>>(
    new Set(featureByOrg.keys())
  );
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.ALL);
  const [visibleLines, setVisibleLines] = useState<VisibleLines>({
    raw: true,
    mapMatched: true,
    rawTrimmedForMapMatching: true,
  });
  const [selectedMapMatchingErrorsCauses, setSelectedMapMatchingErrorsCauses] =
    useState<Set<string>>();

  const isMapMatched = useMemo(() => {
    return (
      rawTraces.features.length > 0 &&
      mapMatchedTraces.features.length > 0 &&
      rawTracesTrimmedForMapMatching.features.length > 0
    );
  }, [rawTraces, mapMatchedTraces, rawTracesTrimmedForMapMatching]);

  useEffect(() => {
    if (traces && regions) {
      const regionsBbox = bbox(regions!!);
      map.fitBounds(regionsBbox as any, { padding: 350, animate: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, regions]);

  useEffect(() => {
    const newTraces = structuredClone(traces) as any;
    newTraces.features = traces.features.filter((it) =>
      selectedOrgs.has(it.properties?.org!!)
    );

    if (selectedMapMatchingErrorsCauses) {
      newTraces.features = newTraces.features.filter((it: any) =>
        it.properties?.failCause?.some((cause: string) =>
          selectedMapMatchingErrorsCauses.has(cause)
        )
      );
    }

    if (
      !newTraces.features.some(
        (it: any) => it.properties?.id === selectedTraceId
      )
    ) {
      setSelectedTraceId(undefined);
    }
    setFilteredTraces(newTraces);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrgs, selectedMapMatchingErrorsCauses, traces]);

  const selectedTrace = useMemo(() => {
    if (selectedTraceId === undefined || traces === undefined) {
      return;
    }

    if (ViewMode.SINGLE && isMapMatched) {
      const rawTrace = rawTraces?.features.find(
        (it) => it?.properties?.id === selectedTraceId
      );

      const rawTrimmedForMapMatchingTrace =
        rawTracesTrimmedForMapMatching?.features.find(
          (it) => it?.properties?.id === selectedTraceId
        );

      const mapMatchedTrace = mapMatchedTraces?.features.find(
        (it) => it?.properties?.id === selectedTraceId
      );

      if (!rawTrace || !mapMatchedTrace) return;

      return { rawTrace, mapMatchedTrace, rawTrimmedForMapMatchingTrace };
    } else {
      const trace = traces?.features.find(
        (it) => it?.properties?.id === selectedTraceId
      );

      if (!trace) return;

      return { rawTrace: trace };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mapMatchedTraces,
    rawTraces,
    selectedTraceId,
    traces,
    selectedTracesType,
  ]);

  const handleCheckboxChange = (traceType: FlowMatrixDebugTraceType) => {
    if (traceType === FlowMatrixDebugTraceType.RAW) {
      setVisibleLines({ ...visibleLines, raw: !visibleLines.raw });
    } else if (traceType === FlowMatrixDebugTraceType.MAP_MATCHED) {
      setVisibleLines({
        ...visibleLines,
        mapMatched: !visibleLines.mapMatched,
      });
    } else {
      setVisibleLines({
        ...visibleLines,
        rawTrimmedForMapMatching: !visibleLines.rawTrimmedForMapMatching,
      });
    }
  };

  return (
    <>
      <FlowMatrixDebugControls
        filteredTracesSize={filteredTraces.features.length}
        filteredTraces={filteredTraces}
        selectedTraceId={selectedTraceId}
        setSelectedTraceId={setSelectedTraceId}
        selectedOrgs={selectedOrgs}
        featureByOrg={featureByOrg}
        setSelectedOrgs={setSelectedOrgs}
        showRegions={showRegions}
        setShowRegions={setShowRegions}
        selectedTracesType={selectedTracesType}
        setSelectedTracesType={setSelectedTracesType}
        viewMode={viewMode}
        setViewMode={setViewMode}
        isMapMatched={isMapMatched}
        tracesOrigin={tracesOrigin}
        setTracesOrigin={setTracesOrigin}
        selectedMapMatchingErrorsCauses={selectedMapMatchingErrorsCauses}
        setSelectedMapMatchingErrorsCauses={setSelectedMapMatchingErrorsCauses}
        traces={traces}
      />

      {viewMode === ViewMode.ALL && (
        <>
          {traces && (
            <>
              <TracesLayer
                showTraces
                showPoints
                color={"#ffffff"}
                traces={filteredTraces}
                layerId={1}
              />
              <FlowMatrixDebugTracesSelector
                nukusTraces={filteredTraces}
                setSelectedTraceId={setSelectedTraceId}
              />
              {selectedTrace && (
                <FlowMatrixDebugColoredTrace
                  sourceId={"selected-trace1"}
                  trace={
                    selectedTracesType === FlowMatrixDebugTraceType.RAW
                      ? selectedTrace.rawTrace
                      : selectedTrace.mapMatchedTrace!!
                  }
                />
              )}
              <TraceSearch
                setSelectedTraceId={setSelectedTraceId}
                traces={filteredTraces}
              />
            </>
          )}
        </>
      )}
      {viewMode === ViewMode.SINGLE && (
        <>
          {selectedTrace && (
            <SingleTraceLayer
              rawTrace={selectedTrace.rawTrace as any}
              mapMatchedTrace={selectedTrace.mapMatchedTrace!!}
              rawTrimmedForMapMatchingTrace={
                selectedTrace.rawTrimmedForMapMatchingTrace!!
              }
              visibleLines={visibleLines}
            />
          )}
        </>
      )}

      {regions && showRegions && <RegionsLayer regions={regions} />}

      <LegendContainer $isTraceActive={selectedTrace !== undefined}>
        {viewMode === ViewMode.SINGLE && (
          <>
            <HFlex alignItems="center">
              <Checkbox
                checked={visibleLines.raw}
                onChange={() =>
                  handleCheckboxChange(FlowMatrixDebugTraceType.RAW)
                }
              />
              <LegendLineColorContainer
                $color={RAW_TRACE_COLOR}
              ></LegendLineColorContainer>{" "}
              Raw trace
            </HFlex>

            <HFlex alignItems="center">
              <Checkbox
                checked={visibleLines.rawTrimmedForMapMatching}
                onChange={() =>
                  handleCheckboxChange(
                    FlowMatrixDebugTraceType.TRIMMED_FOR_MAP_MATCHING
                  )
                }
              />
              <LegendLineColorContainer
                $color={TRIMMED_FOR_MAP_MATCHING_TRACE_COLOR}
              ></LegendLineColorContainer>{" "}
              Raw trimmed for mm
            </HFlex>
            <HFlex alignItems="center">
              <Checkbox
                checked={visibleLines.mapMatched}
                onChange={() =>
                  handleCheckboxChange(FlowMatrixDebugTraceType.MAP_MATCHED)
                }
              />
              <LegendLineColorContainer
                $color={MAP_MATCHED_TRACE_COLOR}
              ></LegendLineColorContainer>{" "}
              Map matched trace
            </HFlex>
          </>
        )}
        <HFlex alignItems="center">
          <LegendColorContainer
            $color={FIRST_TRACE_REPORT_COLOR}
          ></LegendColorContainer>{" "}
          First trace report
        </HFlex>
        <HFlex alignItems="center">
          <LegendColorContainer
            $color={LAST_TRACE_REPORT_COLOR}
          ></LegendColorContainer>{" "}
          Last trace report
        </HFlex>
      </LegendContainer>
    </>
  );
};
