import React, { useState } from "react";
import styled from "styled-components";
import { Button, Caption, Input, Label } from "tombac";
import { FeatureCollection, Geometry } from "@turf/turf";
import { FlowMatrixDebugTraceProps } from "../../../api/model/flowMatrixDebug";
import { BackIcon } from "tombac-icons";

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ScrollableList = styled.ul`
  overflow: hidden;
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
  margin: 0;
  left: 0;
  max-height: 60vh;
`;

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 5px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 2px;
`;

interface Props {
  setSelectedTraceId: (id: string) => void;
  selectedTraceId?: string;
  traces: FeatureCollection<Geometry, FlowMatrixDebugTraceProps>;
}

export const TraceViewerControls = ({
  setSelectedTraceId,
  selectedTraceId,
  traces,
}: Props) => {
  const [searchPattern, setSearchPattern] = useState<string>("");
  const [selectedTraceIndex, setSelectedTraceIndex] = useState<number>(0);

  const handleTraceChange = (id: string, index: number) => {
    setSelectedTraceId(id);
    setSelectedTraceIndex(index);
  };

  const handleNextTrace = () => {
    if (selectedTraceIndex < traces.features.length - 1) {
      const nextTrace = traces.features[selectedTraceIndex + 1];
      setSelectedTraceId(nextTrace.properties?.id!!);
      setSelectedTraceIndex(selectedTraceIndex + 1);
    }
  };

  const handlePrevTrace = () => {
    if (selectedTraceIndex > 0) {
      const prevTrace = traces.features[selectedTraceIndex - 1];
      setSelectedTraceId(prevTrace.properties?.id!!);
      setSelectedTraceIndex(selectedTraceIndex - 1);
    }
  };

  return (
    <SelectorContainer>
      <ControlsContainer>
        {" "}
        <Input
          onChange={(e) => setSearchPattern(e.target.value)}
          $width={"100%"}
          placeholder="Search by id..."
        />{" "}
        <Button $width="12px" shape="circle" size="s" onClick={handleNextTrace}>
          <BackIcon rotate={270} />
        </Button>
        <Button $width="12px" shape="circle" size="s" onClick={handlePrevTrace}>
          <BackIcon rotate={90} />
        </Button>
      </ControlsContainer>

      <ScrollableList>
        {traces.features
          .filter((it) => it.properties?.id.includes(searchPattern))
          .map((feature, index) => (
            <li
              key={index}
              onClick={() => handleTraceChange(feature.properties?.id!!, index)}
            >
              <Button
                $marginBottom={"1px"}
                $width={"100%"}
                size="s"
                style={{
                  fontSize: "11px",
                  justifyContent: "flex-start",
                  height: "auto",
                }}
                onClick={() =>
                  handleTraceChange(feature.properties?.id!!, index)
                }
                disabled={selectedTraceId === feature.properties?.id}
              >
                <ItemContainer>
                  <Label>{feature.properties?.id}</Label>{" "}
                  <Caption>Org: {feature.properties?.org}</Caption>
                  {feature.properties?.failCause && (
                    <Caption>
                      Mm error:{" "}
                      {[...new Set(feature.properties?.failCause)]
                        ?.filter((it) => it)
                        .join(", ")}
                    </Caption>
                  )}
                </ItemContainer>
              </Button>
            </li>
          ))}
      </ScrollableList>
    </SelectorContainer>
  );
};
