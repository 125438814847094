import React, { useEffect, useState } from "react";
import { Feature } from "@turf/turf";
import styled from "styled-components";
import { Box, Button } from "tombac";
import { compareTracesByStartTime, endTime, startTime } from "../utils/traces";

export const ScrollableList = styled.ul`
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

interface Props {
  queriedTraces: Feature[];
  setHoveredTrace: (trace?: Feature) => void;
  setChosenTrace: (trace?: Feature) => void;
}

const TracesList: React.FC<Props> = ({
  queriedTraces,
  setHoveredTrace,
  setChosenTrace,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(true);
    return () => {
      setIsOpen(false);
    };
  }, [queriedTraces]);

  return (
    <>
      {isOpen && (
        <Box maxHeight={"91vh"} $width={"260px"} zIndex={15} color={"white"}>
          <ScrollableList>
            {queriedTraces
              .sort(compareTracesByStartTime)
              .map((trace: Feature, i) => (
                <li
                  key={i}
                  onClick={() => setChosenTrace(trace)}
                  onMouseEnter={() => setHoveredTrace(trace)}
                  onMouseLeave={() => setHoveredTrace(undefined)}
                >
                  <Button
                    marginBottom={"1px"}
                    $width={"100%"}
                    size="s"
                    style={{ fontSize: "11px" }}
                  >
                    {startTime(trace)} - {endTime(trace)},{" "}
                    {trace?.properties?.id}
                  </Button>
                </li>
              ))}
          </ScrollableList>
        </Box>
      )}
    </>
  );
};

export default TracesList;
