import { FeatureCollection, Polygon } from "@turf/turf";
import { useCallback, useEffect, useState } from "react";
import { api, ResponseContentType } from "../api";
import { BASE_API_URL, DELEMONT_FUNCTION_CODE } from "../constants/api";
import { useParams, useSearchParams } from "react-router-dom";

export const useRegions = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [regions, setRegions] =
    useState<FeatureCollection<Polygon, { name: string }>>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const load = useCallback(async () => {
    setIsLoading(true);
    try {
      const futureCollection = await api(
        `${BASE_API_URL}/flow-matrix/${params.id}/regions?${searchParams}&code=${DELEMONT_FUNCTION_CODE}`,
        {
          method: "GET",
        },
        ResponseContentType.JSON
      );

      setRegions(futureCollection);
    } catch (e) {
      console.error(e);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [params.id, searchParams]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    regions,
    isLoading,
    isError,
  };
};
