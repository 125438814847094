import { FeatureCollection, Geometry, GeometryCollection } from "@turf/turf";
import { useCallback, useEffect, useState } from "react";
import { get, ResponseContentType } from "../api";
import { BASE_API_URL, DELEMONT_FUNCTION_CODE } from "../constants/api";
import { readCsvData } from "../utils/csvReader";
import { useParams, useSearchParams } from "react-router-dom";
import { flowMatrixDebugTracesToFeatureCollection } from "../utils/geometry";
import { FlowMatrixDebugTraceProps } from "../api/model/flowMatrixDebug";

const FETCH_FLOW_MATRIX_DEBUG_TRACES_BASE_URL = `${BASE_API_URL}/nukus/debug/flow-matrix`;

const DEBUG_TRACES_URL_PATH = `traces`;
const FAILED_DEBUG_TRACES_URL_PATH = `${DEBUG_TRACES_URL_PATH}/failed`;

const prepareQueryParams = (params: URLSearchParams, failedTraces: boolean) => {
  if (failedTraces) {
    return new URLSearchParams({
      regions: [
        params.get("origin"),
        params.get("destination"),
        params.get("via"),
      ].join(","),
      dateRange: params.get("dateRange")!!,
      timeRange: params.get("timeRange")!!,
    });
  } else {
    return params;
  }
};

export const useFlowMatrixDebugTraces = (failedTraces: boolean) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [rawTraces, setRawTraces] =
    useState<FeatureCollection<Geometry, FlowMatrixDebugTraceProps>>();
  const [rawTracesTrimmedForMapMatching, setRawTracesTrimmedForMapMatching] =
    useState<FeatureCollection<Geometry, FlowMatrixDebugTraceProps>>();
  const [mapMatchedTraces, setMapMatchedTraces] =
    useState<
      FeatureCollection<GeometryCollection, FlowMatrixDebugTraceProps>
    >();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const load = useCallback(async () => {
    setIsLoading(true);
    try {
      const path = failedTraces
        ? FAILED_DEBUG_TRACES_URL_PATH
        : DEBUG_TRACES_URL_PATH;

      const queryParams = prepareQueryParams(searchParams, failedTraces);

      const debugTraces = await get(
        `${FETCH_FLOW_MATRIX_DEBUG_TRACES_BASE_URL}/${params.id}/${path}?${queryParams}&code=${DELEMONT_FUNCTION_CODE}`,
        ResponseContentType.BLOB
      );
      const reader = new FileReader();

      reader.readAsText(debugTraces);
      reader.onload = async (r) => {
        try {
          const debugTraces = await readCsvData(r.target?.result as string);

          const featuresCollections =
            flowMatrixDebugTracesToFeatureCollection(debugTraces);
          setRawTraces(featuresCollections[0]);
          setRawTracesTrimmedForMapMatching(featuresCollections[1]);
          setMapMatchedTraces(featuresCollections[2]);
          setIsError(false);
        } catch (error) {
          console.error(error);
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      };
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      setIsError(true);
    }
  }, [failedTraces, params.id, searchParams]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    rawTraces,
    mapMatchedTraces,
    rawTracesTrimmedForMapMatching,
    isLoading,
    isError,
  };
};
