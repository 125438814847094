import React, { useState } from "react";
import { Button, Modal, Heading } from "tombac";

interface Props {
  clearGroups: () => void;
}

const ClearGroupsButton: React.FC<Props> = ({ clearGroups }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <>
      <Modal isOpen={isModalOpened}>
        <div style={{ textAlign: "center", padding: "20px" }}>
          <Heading level={4} mb={"10px"}>
            Delete all groups?{" "}
            <span style={{ color: "red" }}>It CANNOT be undone!</span>
          </Heading>
          <Button
            onClick={() => {
              clearGroups();
              setIsModalOpened(false);
            }}
            variant={"danger"}
            mr={"10px"}
          >
            Delete
          </Button>
          <Button onClick={() => setIsModalOpened(false)}>Cancel</Button>
        </div>
      </Modal>
      <Button
        onClick={() => {
          setIsModalOpened(true);
        }}
      >
        Delete all
      </Button>
    </>
  );
};

export default ClearGroupsButton;
