import { ControlsContainer } from "../../../TracesPage";
import React, { useState } from "react";
import { FeatureCollection, Geometry } from "@turf/turf";
import { FlowMatrixDebug } from "../FlowMatrixDebug";
import {
  FlowMatrixDebugTraceProps,
  TracesOrigin,
} from "../../../../api/model/flowMatrixDebug";
import { CsvUploadModal } from "./CsvUploadModal";

export const DebugCsvPage = () => {
  const [isOpened, setIsOpened] = useState(true);
  const [traceOrigin, setTraceOrigin] = useState(TracesOrigin.ANALYSIS);
  const [rawTraces, setRawTraces] =
    useState<FeatureCollection<Geometry, FlowMatrixDebugTraceProps>>();
  const [mapMatchedTraces, setMapMatchedTraces] =
    useState<FeatureCollection<any, FlowMatrixDebugTraceProps>>();
  const [rawTracesTrimmedForMapMatching, setRawTracesTrimmedForMapMatching] =
    useState<FeatureCollection<Geometry, FlowMatrixDebugTraceProps>>();

  return (
    <>
      <ControlsContainer>
        <CsvUploadModal
          setRawTraces={setRawTraces}
          setMapMatchedTraces={setMapMatchedTraces}
          setRawTracesTrimmedForMapMatching={setRawTracesTrimmedForMapMatching}
          traceOrigin={traceOrigin}
          setTraceOrigin={setTraceOrigin}
          isOpen={isOpened}
          setIsOpen={setIsOpened}
        />
        {rawTraces && mapMatchedTraces && rawTracesTrimmedForMapMatching && (
          <FlowMatrixDebug
            rawTraces={rawTraces}
            mapMatchedTraces={mapMatchedTraces}
            rawTracesTrimmedForMapMatching={rawTracesTrimmedForMapMatching}
            tracesOrigin={traceOrigin}
            setTracesOrigin={(failedTraces: TracesOrigin) => {}}
          />
        )}
      </ControlsContainer>
    </>
  );
};
