import "tombac-icons/react/style.css";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {
  AuthProvider,
  getConfig,
  RedirectToLogin,
  useAuth,
} from "legoland-sdk";
import { TombacApp } from "tombac";

const AuthOnly = ({ children }: any) => {
  const { isPristine, isAuthenticated } = useAuth();

  if (isPristine) {
    return null;
  }

  if (!isAuthenticated) {
    return <RedirectToLogin />;
  }

  return <>{children}</>;
};

console.log(getConfig("production"));

ReactDOM.render(
  <React.StrictMode>
    <TombacApp theme={{ settings: { modalZIndex: 16 } }}>
      <AuthProvider config={getConfig("production")}>
        <AuthOnly>
          <App />
        </AuthOnly>
      </AuthProvider>
    </TombacApp>
  </React.StrictMode>,
  document.getElementById("root")
);
